import { useCallback, useState } from 'react';

import AttachFile from '@mui/icons-material/AttachFile';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import IconButton from '@/components/atoms/IconButton/IconButton';
import Brain from '@/components/atoms/Icons/Brain';
import IconCollection from '@/components/atoms/Icons/Collection';
import CarouselOld from '@/components/organisms/Dialogs/CarouselOld/CarouselOld';
import { useAccount } from '@/hooks/useAccount';
import useDialogs from '@/hooks/useDialogs';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { useMarkdownToHtml } from '@/hooks/useMarkdownToHtml';
import { ConversationSource } from '@/models/chat';
import {
  AmendPayload,
  Correction,
  Message,
  NodeStack,
  ResponseMessage,
  TryItCollection,
} from '@/models/tryIt';
import { IconBroadcast } from '@/modules/broadcast/icons/Broadcast';
import { Attachment } from '@/modules/humanChat/components/Conversation/MessageArea/Attachment/Attachment';
import { useMessageBubble } from '@/modules/TryIt/hooks/useMessageBubble';
import { GoToResponseIcon } from '@/modules/TryIt/Icons/GoToResponseIcon';
import {
  selectTryItAction,
  selectTryItRequisite,
} from '@/modules/TryIt/redux/actions';
import { selectIsTryItReplay } from '@/modules/TryIt/redux/selectors';
import {
  isMessageFromCollection,
  removeDuplicateDocuments,
} from '@/modules/TryIt/utils/helper';
import { getActionsSelector } from '@/redux/dialogs/selectors';
import { popModal } from '@/redux/modals/actions';
import { resolveBrainsPath } from '@/util/util';

import AgentMessage from './AgentMessage';
import { PromptMessage } from './PromptMessage';
import { ResponsesWrapper } from './ResponsesWrapper/ResponsesWrapper';
import { AmendWrapper } from '../AmendWrapper/AmendWrapper';

import styles from '../MessageNode/MessageNode.module.scss';

export interface MessageBubbleProps {
  message: Message;
  onOptionClick: (text: string) => void;
  onPostback: (text: string) => void;
  collection?: TryItCollection;
  nodes_stack?: NodeStack[];
  brainId: string;
  author_type?: string;
  author_id?: string;
  isAccountReplay?: boolean;
  isAudio?: boolean;
  source: ConversationSource;
  amendPayload?: AmendPayload;
  correction?: Correction;
}

interface ResponseMessagesType {
  isAccountReplay?: boolean;
  source?: ConversationSource;
  collection?: TryItCollection;
  onOptionClick: (text: string) => void;
  onPostback: (text: string) => void;
  onResponseClick: (id: string) => void;
  response: ResponseMessage;
  amendPayload?: AmendPayload;
  correction?: Correction;
}

const generateFragments = (number: number, isSelectedDocuments: boolean) => {
  let fragments = '';
  for (let i = 1; i <= number; i++) {
    fragments += `<span class="${cn(styles.documentNumber, { [styles.isSelected]: isSelectedDocuments })}">${i}</span>`;
  }
  return `<span>${fragments}</span>`;
};

// Subcomponent
const ResponseMessages = ({
  response,
  onOptionClick,
  onPostback,
  collection,
  onResponseClick,
  isAccountReplay,
  source,
  amendPayload,
  correction,
}: ResponseMessagesType) => {
  const isCollectionReply = isMessageFromCollection(collection);
  const isBroadcastReply = !isCollectionReply && !response.action_id;
  const text = 'text' in response ? response.text : '';
  const { html } = useMarkdownToHtml(text);
  const { t } = useTranslation();
  const uniqueDocuments = removeDuplicateDocuments(collection?.fragments || []);

  const { external_urls } = collection || {};

  const fragments = generateFragments(
    external_urls?.length || uniqueDocuments?.length || 0,
    external_urls?.length > 0
  );
  const matchAnyClosingTagRegex = /(<\/\w+>)$/;
  const htmlWithFragments = html.replace(
    matchAnyClosingTagRegex,
    fragments + '$1'
  );

  const [optionChosen, setOptionChosen] = useState(false);
  const isReplay = useSelector(selectIsTryItReplay);

  const handleOptionClick = (text: string) => {
    if (!isReplay) {
      onOptionClick(text);
      setOptionChosen(true);
    }
  };

  const Icon = () => {
    if (isCollectionReply) {
      return <IconCollection color="var(--icon-default-gray)" size={24} />;
    } else if (isBroadcastReply) {
      return (
        <IconBroadcast
          color="var(--icon-default-gray)"
          className={styles.responseTypeIcon}
          size={24}
        />
      );
    }
    return (
      <Brain
        color="var(--icon-default-gray)"
        className={styles.responseTypeIcon}
        size={24}
      />
    );
  };

  switch (response.type) {
    case 'text':
    case 'disambiguation':
      // for test webhook
      if ('texts' in response && response.texts) {
        const texts = response.texts as string[];
        return (
          <>
            {texts.map((text, index) => (
              <p
                // eslint-disable-next-line react/no-array-index-key
                key={`${text}_${index}`}
                className={cn(styles.bubble, styles.brain)}
              >
                {text}
              </p>
            ))}
            {!optionChosen && response.options && (
              <div className={styles.optionsContainer}>
                {response.options.map((option) => (
                  <button
                    key={`option-${option.text}`}
                    className={cn(styles.bubble, styles.option, {
                      [styles.optionDisabled]: isReplay,
                    })}
                    onClick={() => {}}
                    type="button"
                  >
                    {option.label}
                  </button>
                ))}
              </div>
            )}
          </>
        );
      }

      return (
        <>
          <div className={styles.response}>
            <Icon />

            <div className={cn(styles.bubble, styles.brain)}>
              <AmendWrapper
                amendPayload={amendPayload}
                isAmended={!!correction}
              >
                <Typography
                  variant="body-regular"
                  component="span"
                  dangerouslySetInnerHTML={{
                    __html:
                      isCollectionReply && !isAccountReplay
                        ? htmlWithFragments
                        : html,
                  }}
                />
              </AmendWrapper>
            </div>

            {!isReplay &&
              !isCollectionReply &&
              response.type !== 'disambiguation' && (
                <IconButton
                  ariaLabel={t('try_it.go_to_response')}
                  onClick={() => onResponseClick(response.action_id)}
                >
                  <GoToResponseIcon className={styles.goToResponseIcon} />
                </IconButton>
              )}
          </div>

          {!optionChosen && response.options && (
            <div className={styles.optionsContainer}>
              {response.options.map((option) => (
                <Typography
                  variant="body-regular"
                  key={JSON.stringify(option)}
                  className={cn(styles.option, {
                    [styles.optionDisabled]: isReplay,
                  })}
                  onClick={() => handleOptionClick(option.text)}
                  component="button"
                >
                  {option.label}
                </Typography>
              ))}
            </div>
          )}
        </>
      );
    case 'url':
      return (
        <div className={styles.response}>
          <Brain
            color="var(--icon-default-gray)"
            className={styles.responseTypeIcon}
            size={24}
          />
          <p className={cn(styles.bubble, styles.brain)}>
            <a
              href={response.url}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: 'flex',
                alignItems: 'center',
                color: 'var(--text-default-blue)',
              }}
            >
              {response.url}
            </a>
          </p>

          {!isReplay && !isCollectionReply && (
            <IconButton
              ariaLabel={t('try_it.go_to_response')}
              onClick={() => onResponseClick(response.action_id)}
            >
              <GoToResponseIcon className={styles.goToResponseIcon} />
            </IconButton>
          )}
        </div>
      );
    case 'audio':
      return (
        <div className={styles.response}>
          <Brain
            color="var(--icon-default-gray)"
            className={styles.responseTypeIcon}
            size={24}
          />
          <p className={cn(styles.bubble, styles.brain, styles.isMedia)}>
            <audio src={response.url} controls />
          </p>
        </div>
      );
    case 'image':
      return (
        <div className={styles.response}>
          <Brain
            color="var(--icon-default-gray)"
            className={styles.responseTypeIcon}
            size={24}
          />
          <p className={cn(styles.bubble, styles.brain, styles.isMedia)}>
            {response.id ? (
              <Attachment
                source={source}
                attachment={{
                  id: response.id,
                  type: 'image',
                  mime_type: 'image/jpeg',
                }}
              />
            ) : (
              <img src={response.url} alt={response.name} />
            )}
          </p>
          {!isReplay && !isCollectionReply && (
            <IconButton
              ariaLabel={t('try_it.go_to_response')}
              onClick={() => onResponseClick(response.action_id)}
            >
              <GoToResponseIcon className={styles.goToResponseIcon} />
            </IconButton>
          )}
        </div>
      );
    case 'video':
      return (
        <div className={styles.response}>
          <Brain
            color="var(--icon-default-gray)"
            className={styles.responseTypeIcon}
            size={24}
          />
          <p className={cn(styles.bubble, styles.brain, styles.isMedia)}>
            {response.id ? (
              <Attachment
                source={source}
                attachment={{
                  id: response.id,
                  type: 'video',
                  mime_type: 'video/mp4',
                }}
              />
            ) : (
              <video src={response.url} controls />
            )}
          </p>
          {!isReplay && !isCollectionReply && (
            <IconButton
              ariaLabel={t('try_it.go_to_response')}
              onClick={() => onResponseClick(response.action_id)}
            >
              <GoToResponseIcon className={styles.goToResponseIcon} />
            </IconButton>
          )}
        </div>
      );
    case 'file':
      return (
        <div className={styles.response}>
          <Brain
            color="var(--icon-default-gray)"
            className={styles.responseTypeIcon}
            size={24}
          />
          <p className={cn(styles.bubble, styles.brain)}>
            {response.id ? (
              <Attachment
                source={source}
                attachment={{
                  id: response.id,
                  type: 'file',
                  mime_type: '',
                }}
              />
            ) : (
              <a
                href={response.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'var(--text-default-blue)',
                }}
              >
                <AttachFile style={{ marginRight: 'var(--space-4)' }} />
                {response.name}
              </a>
            )}
          </p>
          {!isReplay && !isCollectionReply && (
            <IconButton
              ariaLabel={t('try_it.go_to_response')}
              onClick={() => onResponseClick(response.action_id)}
            >
              <GoToResponseIcon className={styles.goToResponseIcon} />
            </IconButton>
          )}
        </div>
      );
    case 'carousel':
      return (
        <div className={styles.response}>
          <Brain
            color="var(--icon-default-gray)"
            className={styles.responseTypeIcon}
            size={24}
          />
          <div className={styles.carouselContainer}>
            <CarouselOld
              onPostback={onPostback}
              cards={response.cards}
              isReplay={isReplay}
              slidesPerView={1.75}
            />
          </div>
          {!isReplay && !isCollectionReply && (
            <IconButton
              ariaLabel={t('try_it.go_to_response')}
              onClick={() => onResponseClick(response.action_id)}
            >
              <GoToResponseIcon className={styles.goToResponseIcon} />
            </IconButton>
          )}
        </div>
      );
    case 'webview':
    case 'survey':
      return (
        <div className={styles.response}>
          <Brain
            color="var(--icon-default-gray)"
            className={styles.responseTypeIcon}
            size={24}
          />
          <div className={styles.webviewContainer}>
            <p>{response.name}</p>
            <div className={styles.webviewButtonContainer}>
              <p>{response.label}</p>
            </div>
          </div>
          {!isReplay && !isCollectionReply && (
            <IconButton
              ariaLabel={t('try_it.go_to_response')}
              onClick={() => onResponseClick(response.action_id)}
            >
              <GoToResponseIcon className={styles.goToResponseIcon} />
            </IconButton>
          )}
        </div>
      );
    default:
      return null;
  }
};

export const MessageBubble = ({
  message,
  onOptionClick,
  onPostback,
  collection,
  nodes_stack,
  brainId,
  author_type,
  author_id,
  isAccountReplay,
  source,
  amendPayload,
  correction,
}: MessageBubbleProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { slug } = useAccount();
  const { onGoToEntity } = useMessageBubble();
  const { dialogs } = useDialogs(brainId);
  const allActions = getActionsSelector(dialogs);
  const dispatch = useDispatch();
  const { ai_agents } = useFeatureFlag();

  const goToResponse = useCallback(
    (actionId: string) => {
      const selectedAction = allActions.find(
        (a: { action_id: string }) => a.action_id === actionId
      );
      if (!brainId || !selectedAction || !actionId) {
        return;
      }

      dispatch(popModal());
      // select an action inside a requisite or an action of a node
      if (selectedAction?.if_no_requisite) {
        dispatch(
          selectTryItRequisite({
            node_id: selectedAction.node_id,
            requisite_idx: selectedAction.requisite_idx,
            if_no_requisite: selectedAction.if_no_requisite,
          })
        );
      } else if (selectedAction.requisite_idx == null) {
        dispatch(
          selectTryItAction({
            node_id: selectedAction.node_id,
            action_id: actionId,
          })
        );
      } else {
        dispatch(
          selectTryItRequisite({
            node_id: selectedAction.node_id,
            requisite_idx: selectedAction.requisite_idx,
          })
        );
      }
      navigate(
        resolveBrainsPath(
          `/${slug}/brains/${brainId}/dialogs/${selectedAction.dialog_id}`,
          ai_agents
        )
      );
    },
    [allActions, brainId, dispatch, navigate, slug, ai_agents]
  );
  switch (message.type) {
    case 'broadcast': {
      return (
        <>
          {message.responses?.map((response) => (
            <ResponseMessages
              key={`${JSON.stringify(response)}`}
              response={response}
              onOptionClick={onOptionClick}
              onPostback={onPostback}
              collection={collection}
              onResponseClick={goToResponse}
              isAccountReplay={isAccountReplay}
              source={source}
            />
          ))}
        </>
      );
    }
    case 'user':
      return (
        <PromptMessage
          text={message.text}
          entities={message.entities}
          onGoToEntity={onGoToEntity}
          t={t}
          isAudio={message.isAudio}
          attachments={message?.attachments}
          source={source}
        />
      );
    case 'agent':
      return (
        <AgentMessage
          text={message.text}
          author_type={author_type}
          author_id={author_id}
          template={message?.template}
          body={message?.body}
        />
      );
    case 'brain':
      return (
        <ResponsesWrapper nodes_stack={nodes_stack}>
          {message.responses?.map((response) => (
            <ResponseMessages
              key={`${JSON.stringify(response)}`}
              response={response}
              onOptionClick={onOptionClick}
              onPostback={onPostback}
              collection={collection}
              onResponseClick={goToResponse}
              isAccountReplay={isAccountReplay}
              amendPayload={amendPayload}
              correction={correction}
            />
          ))}
        </ResponsesWrapper>
      );
    case 'error': {
      return <p className={cn(styles.bubble, styles.error)}>{message.text}</p>;
    }

    default:
  }
};
