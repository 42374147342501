import { Condition } from '../../../ts/models/condition';

export type Trigger = {
  type: string;
  delay_in_seconds?: number;
  isOpen?: boolean;
  isError?: boolean;
};

export enum ActionType {
  ASSIGN_BRAIN = 'assign_brain',
  ASSIGN_AI_AGENT = 'assign_ai_agent',
  ASSIGN_COLLECTION = 'assign_collection',
  ASSIGN_DEPARTMENT = 'assign_department',
  APPLY_BUNDLE = 'apply_bundle',
  ADD_TAG = 'add_tag',
  CLOSE_CONVERSATION = 'close_conversation',
}

export type Action = {
  type: ActionType;
  brain_parent_id?: string;
  sla_id?: string;
  tag?: string;
  brain_version?: number;
  isOpen?: boolean;
  isError?: boolean;
  bundle_id?: string;
  collection_id?: string;
  department_id?: string;
};

export type Option = {
  value?: string;
  label?: string;
  id?: number;
  type?: string;
};

export type Rule = {
  readonly rule_id: string;
  desk_id: string;
  name: string;
  description: string;
  status: 'active' | 'inactive';
  metadata: {
    source: 'broadcast' | 'whatsapp_test' | 'onboarding' | 'manual';

    broadcast_id?: string;
  };
  position: number;
  triggers: Trigger[];
  condition: Condition;
  actions: Action[];
  options?: Option[];
  dirty: boolean;
  last_used_at?: string | null;
  created?: string;
  updated?: string;
  actionsOptions: { type: string }[];
};

export type Rules = { rules: Rule[] };
