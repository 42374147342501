import { useState } from 'react';

import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

import Input from '@/components/atoms/Input/Input';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { OptionBase } from '@/models/common';
import { AccountAnalyticsFilterType } from '@/modules/analytics/models';

import styles from './FilterSearchList.module.scss';

type Option = OptionBase<{
  value: AccountAnalyticsFilterType;
  input?: string;
  valuePrefix?: string;
  trueLabel?: string;
}>;
interface Props {
  className?: string;
  onClick: (option: Option) => void;
  options: Option[];
}
const FilterSearchList = ({ className, onClick, options }: Props) => {
  const [searchFilter, setSearchFilter] = useState('');
  const { t } = useTranslation();
  const { ai_agents } = useFeatureFlag();

  const filteredOptions = options.filter((option) => {
    // Hide Language Model filter if AI Agents is not enabled
    if (!ai_agents && option.value === 'languageModelIds') {
      return false;
    }

    return true;
  });
  return (
    <div
      className={cn(styles.list_container, {
        [className]: className,
      })}
    >
      <div className={styles.input}>
        <Input
          placeholder={t('common.search')}
          size="small"
          onChange={(event) => {
            setSearchFilter(event.target.value?.toLowerCase());
          }}
        />
      </div>
      {filteredOptions
        .filter((option) => option.label?.toLowerCase().includes(searchFilter))
        .map((option) => (
          <button
            key={option.value}
            className={styles.option_button}
            onClick={() => onClick(option)}
          >
            {option.label}
          </button>
        ))}
      {isEmpty(
        filteredOptions.filter((option) =>
          option.label?.toLowerCase().includes(searchFilter)
        )
      ) && <div className={styles.empty}>No result</div>}
    </div>
  );
};

export default FilterSearchList;
