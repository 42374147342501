// Some day this file will be important. Now it's just a diamond in the rough.
/*****************************-Work in progress-*****************************/
/*TODO:
  - add conditions
  - send request to server
  - add emoji field
  - use it for edition
*/
import { Controller, useForm } from 'react-hook-form';

import Input from '@/components/atoms/Input/Input';
import Switch from '@/components/atoms/Switch/Switch';
import { ViewType } from '@/models/views';

import Condition from '../../Condition/Condition';
import Modal from '../Modal';

export const ModalViews = () => {
  const { register, watch, control } = useForm({
    defaultValues: {
      name: '',
      type: ViewType.PRIVATE,
    },
  });
  const text = watch('name');
  return (
    <Modal size="medium" title={text?.length ? text : 'Add view'}>
      <Input register={register('name')} />
      <Controller
        control={control}
        name="type"
        render={({ field }) => (
          <Switch
            checked={field.value === ViewType.PUBLIC}
            onChange={(e) =>
              field.onChange(
                e.target.checked ? ViewType.PUBLIC : ViewType.PRIVATE
              )
            }
          />
        )}
      />
      <Condition />
    </Modal>
  );
};
