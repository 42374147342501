import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import useLogs from '@/hooks/useLogs';
import useTryIt from '@/modules/TryIt/hooks/useTryIt';
import {
  ThumbsDownIcon,
  ThumbsUpIcon,
} from '@/modules/TryIt/Icons/ThumbsIcons';
import {
  selectThumbsDown,
  selectThumbsUp,
} from '@/modules/TryIt/redux/selectors';
import { selectSessionId } from '@/redux/session/selectors';
import { scrollToElementById } from '@/util/util';

import { InformationFieldNew } from '../InformationField/InformationFieldNew';

import styles from './RatingSection.module.scss';

interface Props {
  rating: 1 | 2 | 3 | 4 | 5;
  feedback: string;
}
const Reactions = () => {
  const thumbsUp = useSelector(selectThumbsUp);
  const thumbsDown = useSelector(selectThumbsDown);
  const { t } = useTranslation();
  const sessionId = useSelector(selectSessionId);
  const { content } = useLogs(undefined, sessionId);

  const { handleNodeClick } = useTryIt(
    content?.messages,
    undefined,
    content?.tags
  );

  if (thumbsUp.length === 0 && thumbsDown.length === 0) {
    return null;
  }

  return (
    <div className={styles.reactions}>
      {thumbsUp.length > 0 && (
        <div>
          <Box
            display="flex"
            gap="var(--space-8)"
            alignItems="center"
            component="h4"
            mb="var(--space-8)"
          >
            <Typography
              variant="label-caps-large"
              color="var(--text-default-gray-light)"
            >
              {t('try_it.thumbs_up')}
            </Typography>
            <Typography
              variant="label-regular"
              color="var(--text-default-blue)"
            >
              ({thumbsUp.length})
            </Typography>
          </Box>
          <ul className={styles.thumbs}>
            {thumbsUp.map((item) => (
              <li key={item.request_id} className={styles.thumbs__item}>
                <ThumbsUpIcon color="var(--icon-default-blue)" />
                <Typography
                  variant="label-semi-bold"
                  color="var(--text-default-blue)"
                  component="button"
                  onClick={() => {
                    scrollToElementById(item.request_id);
                    handleNodeClick(item.nodeIndex);
                  }}
                  className={styles.thumbs__item__text}
                >
                  {item.firstMessageText}
                </Typography>
              </li>
            ))}
          </ul>
        </div>
      )}

      {thumbsDown.length > 0 && (
        <div>
          <Box
            display="flex"
            gap="var(--space-8)"
            alignItems="center"
            component="h4"
            mb="var(--space-8)"
          >
            <Typography
              variant="label-caps-large"
              color="var(--text-default-gray-light)"
            >
              {t('try_it.thumbs_down')}
            </Typography>
            <Typography
              variant="label-regular"
              color="var(--text-default-blue)"
            >
              ({thumbsDown.length})
            </Typography>
          </Box>
          <ul className={styles.thumbs}>
            {thumbsDown.map((item) => (
              <li key={item.request_id} className={styles.thumbs__item}>
                <ThumbsDownIcon color="var(--icon-default-blue)" />
                <Typography
                  variant="label-semi-bold"
                  color="var(--text-default-blue)"
                  component="button"
                  onClick={() => {
                    scrollToElementById(item.request_id);
                    handleNodeClick(item.nodeIndex);
                  }}
                  className={styles.thumbs__item__text}
                >
                  {item.firstMessageText}
                </Typography>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const RatingSection = ({ rating, feedback }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <InformationFieldNew
        type="rating"
        label={t('analytics.fields.rating')}
        value={rating}
      />
      <InformationFieldNew
        type="feedback"
        label={t('common.feedback')}
        value={feedback}
      />

      <Reactions />
    </div>
  );
};

export default RatingSection;
