import { useEffect } from 'react';

import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import Button from '@/components/atoms/Button/Button/Button';
import ContextualHelp from '@/components/atoms/ContextualHelp/ContextualHelp';
import Sales from '@/components/atoms/Icons/Sales';
import Version from '@/components/atoms/Icons/Version';
import TileEmptyPage from '@/components/organisms/Tile/TileEmptyPage/TileEmptyPage';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import useVersions from '@/hooks/useVersions';
import { actions } from '@/models/permissions';
import { PageName } from '@/models/segment';
import { RootState } from '@/models/state';
import { useBillingState } from '@/modules/billing/hooks/useBillingState';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountSlug, selectBrainId } from '@/redux/session/selectors';
import { pageView } from '@/segment/segment';

import { useVersionsTable } from './useVersionsTable';
import { VersionTimeline } from './VersionsTimeline';

import styles from './Versions.module.scss';

export const Versions = () => {
  useEffect(() => {
    pageView(PageName.VERSIONS);
  }, []);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const brainId = useSelector(selectBrainId);
  const slug = useSelector(selectAccountSlug);
  const { maxVersionsReached, versions, maxVersions, versionsWithoutDraft } =
    useVersions(brainId);
  const { noVersions, handleCreateVersion } = useVersionsTable();
  const canWrite = useSelector(
    (state: RootState) => getPermissions(state, 'brains', actions.WRITE),
    shallowEqual
  );
  const billingState = useBillingState();

  const handleClick = () => {
    navigate(`/${slug}/account/billing`, {
      state: { shouldTriggerAction: Date.now() },
    });
  };

  // This is a workaround to hide the version tile on AI Agents page
  // We will remove this when we will totally replace Brains with AI Agents
  const location = useLocation();
  const isAIAgentsPath = location.pathname.includes('/ai-agents');

  return (
    <PageContentWrapper
      newPlain
      readOnly={!canWrite}
      noGutters={isAIAgentsPath}
    >
      <div className={styles.dataGridWrapper}>
        {maxVersionsReached && (
          <div className={styles.limitContainer}>
            <Typography variant="subheading-semi-bold">
              {t('versions.saved_versions')}
            </Typography>
            <Typography>
              {t('billing.versions_limits', {
                0: versions?.length - 1,
                1: maxVersions,
                2: billingState.plan_name,
              })}
            </Typography>
            <Button variant="tertiary" onClick={handleClick}>
              <Sales color="var(--icon-default-blue)" />
              <Typography
                variant="body-semi-bold"
                color="var(--icon-default-blue)"
              >
                {t('billing.upgrade')}
              </Typography>
            </Button>
          </div>
        )}
        {noVersions ? (
          <TileEmptyPage
            title={t('versions.no_versions')}
            notClickable
            body={
              <>
                {t('versions.no_versions_body')}
                <br />{' '}
                <Button
                  onClick={handleCreateVersion}
                  variant="tertiary"
                  disabled={!canWrite}
                >
                  {t('versions.add_version')}
                </Button>
              </>
            }
            icon={<Version color="var(--icon-default-blue)" size={100} />}
          />
        ) : (
          <>
            <ContextualHelp
              title={t('versions.contextual_title')}
              name="about_versions"
            >
              {t('versions.contextual_message')}
            </ContextualHelp>
            {versionsWithoutDraft?.map((v, i) => (
              <VersionTimeline
                version={v}
                key={v.created}
                isLast={versionsWithoutDraft?.length - 1 === i}
                index={i}
                versions={versionsWithoutDraft}
              />
            ))}
          </>
        )}
      </div>
    </PageContentWrapper>
  );
};
