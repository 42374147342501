import { Condition } from '../../ts/models/condition';

export enum ViewType {
  PRIVATE = 'private',
  PUBLIC = 'public',
}

export interface View {
  view_id: string;
  desk_id: string;
  name: string;
  type: ViewType;
  created_by?: string | null;
  icon?: string;
  condition: Condition;
}
