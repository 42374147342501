import { useCallback, useEffect, useMemo } from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { BackgroundWrapper } from '@/components/atoms/BackgroundWrapper/BackgroundWrapper';
import Button from '@/components/atoms/Button/Button/Button';
import Dropdown from '@/components/atoms/DropdownList/PlaceholderDropdown';
import Input from '@/components/atoms/Input/Input';
import Link from '@/components/atoms/Link/Link';
import { usePreferences } from '@/hooks/usePreferences';
import useUser from '@/hooks/useUser';
import { PageName } from '@/models/segment';
import { Preferences } from '@/models/user';
import { selectIsAuthenticated, selectUser } from '@/redux/user/selectors';
import { pageView } from '@/segment/segment';
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from '@/util/constants';
import { removeEmptyValues } from '@/util/util';
import { LENGTH_S, createInputSchema, isRequired } from '@/util/validator';

import styles from '../Signup.module.scss';

type SignupParams = {
  name: string;
  job: string;
  industry: string;
  accept_terms: boolean;
  marketing_emails: boolean;
  company: string;
};

const WelcomeAboard = () => {
  const { t } = useTranslation();
  const { signUp, signUpStatus } = useUser();
  const { updateUserPreferences } = usePreferences();
  const user = useSelector(selectUser);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get('to') || '/';
  // After users signup, they are redirected to the onboarding page by default
  // unless there is a custom onboarding in the `to` query param
  const selectedOnboarding = redirectTo.startsWith('/onboarding')
    ? redirectTo
    : '/new-onboarding';
  useEffect(() => {
    pageView(PageName.WELCOME);
  }, []);

  useEffect(() => {
    window.X_MOVEO_ACCOUNT_SLUG = undefined;
  }, []);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
  } = useForm<SignupParams>({
    defaultValues: {
      name: user?.name,
    },
    mode: 'onChange',
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(redirectTo);
    }
  }, [isAuthenticated, redirectTo, navigate]);

  const jobOptions = useMemo(
    () =>
      [
        'business_analyst',
        'customer_support',
        'designer',
        'developer',
        'executive',
        'hr_manager',
        'marketing',
        'product_manager',
        'sales',
        'other',
      ].map((j) => ({ value: j, label: j })),
    []
  );

  const industryOptions = useMemo(
    () =>
      [
        'banking_insurance',
        'e_commerce',
        'education',
        'energy_utilities',
        'finance',
        'government',
        'healthcare',
        'manufacturing',
        'media_advertising',
        'non_profits',
        'retail',
        'technology',
        'telecommunications',
        'transportation',
        'travel_hospitality',
        'other',
      ].map((i) => ({ value: i, label: i })),
    []
  );

  const handleCreateAccount = useCallback(
    ({ name }) => {
      signUp(
        { name },
        {
          onSuccess: () => {
            navigate(selectedOnboarding);
            updateUserPreferences(
              removeEmptyValues({
                job: getValues('job'),
                industry: getValues('industry'),
                company: getValues('company'),
                marketing_emails: getValues('marketing_emails'),
              }) as Preferences
            );
          },
        }
      );
    },
    [getValues, navigate, selectedOnboarding, signUp, updateUserPreferences]
  );

  return (
    <BackgroundWrapper title={t('signup.welcome_aboard')} showBranding>
      <div className={styles.wrapper}>
        <div className={styles.aboardForm}>
          <form onSubmit={handleSubmit(handleCreateAccount)}>
            <Input
              size="medium"
              name="name"
              id="name"
              label={t('signup.name')}
              placeholder={t('signup.name_placeholder')}
              lowercaseLabel
              register={register('name', createInputSchema(LENGTH_S))}
              errorMessage={errors.name?.message}
            />
            <Input
              size="medium"
              name="company"
              id="company"
              label={t('signup.company')}
              placeholder={t('signup.name_placeholder')}
              lowercaseLabel
              register={register('company', {
                maxLength: {
                  value: 20,
                  message: t('validation.less_than', { 0: 20 }),
                },
              })}
              errorMessage={errors.company?.message}
            />

            <div className={styles.dropdownWrapper}>
              <Controller
                render={({ field: { value, onChange } }) => {
                  return (
                    <Dropdown
                      option={value}
                      setOption={onChange}
                      options={jobOptions}
                      translationPrefix="signup.jobs."
                      label="job_function"
                      placeholder="job_function_placeholder"
                    />
                  );
                }}
                name="job"
                control={control}
              />

              <Controller
                render={({ field: { value, onChange } }) => {
                  return (
                    <Dropdown
                      option={value}
                      setOption={onChange}
                      options={industryOptions}
                      translationPrefix="signup.industries."
                      label="industry"
                      placeholder="industry_placeholder"
                    />
                  );
                }}
                name="industry"
                control={control}
              />
            </div>
            <div className={styles.checkboxWrapper}>
              <FormControlLabel
                classes={{ label: styles.checkboxLabel }}
                control={
                  <Controller
                    name="accept_terms"
                    control={control}
                    rules={isRequired}
                    render={({ field: props }) => (
                      <Checkbox
                        className={styles.checkbox}
                        {...props}
                        color="primary"
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
                label={
                  <div className={styles.label}>
                    <Trans i18nKey="signup.agree_terms">
                      <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        href={PRIVACY_POLICY_URL}
                      >
                        {t('signup.privacy_policy_link')}
                      </Link>
                      <Link
                        href={TERMS_OF_USE_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('signup.terms_of_service_link')}
                      </Link>
                    </Trans>
                  </div>
                }
              />
              <FormControlLabel
                classes={{ label: styles.checkboxLabel }}
                control={
                  <Controller
                    name="marketing_emails"
                    control={control}
                    render={({ field: props }) => (
                      <Checkbox
                        {...props}
                        color="primary"
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
                label={t('signup.agree_marketing')}
              />
            </div>
            <Button
              type="submit"
              size="xlarge"
              isFullWidth
              className={styles.button}
              isLoading={signUpStatus === 'pending'}
              disabled={!isValid}
            >
              {t('signup.create_account')}
            </Button>
          </form>
        </div>
      </div>
    </BackgroundWrapper>
  );
};

export default WelcomeAboard;
