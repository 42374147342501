import { useEffect, useRef, useState, MouseEvent, KeyboardEvent } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { TypographyVariants, useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { useForm, Controller, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AnyObject, ObjectSchema } from 'yup';

import { isKeyEnter, preventClickThrough } from '@/util/util';

import { getBorderColor, getHoverBorderColor, selectAllText } from './helper';
import { ErrorIcon } from '../Icons/Error';

import styles from './EditableText.module.scss';

type EditableTextProps<T = {}> = {
  defaultValue: string;
  canEdit?: boolean;
  disabledText?: string;
  variant?: keyof TypographyVariants;
  color?: string;
  showError?: boolean;
  onSubmit: (text: string) => void;
  onError?: (x: string) => void;
  className?: string;
  validationSchema?: T;
  overflowEllipsis?: boolean;
  startFromEditMode?: boolean;
  placeholder?: string;
  customSanitizer?: (value: string) => string;
};

export const EditableText = <T,>({
  defaultValue,
  canEdit = true,
  disabledText,
  variant = 'label-regular',
  showError = true,
  onSubmit,
  onError,
  className,
  validationSchema,
  overflowEllipsis = false,
  startFromEditMode = false,
  color = 'var(--text-default-gray)',
  placeholder,
  customSanitizer,
}: EditableTextProps<T>) => {
  // Local state
  const [prevError, setPrevError] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);

  // Hooks
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    control,
    reset,
    formState: { errors },
    watch,
    setValue,
    trigger,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      inputField: defaultValue ?? '',
    },
    resolver: validationSchema
      ? yupResolver(
          validationSchema as ObjectSchema<FieldValues, AnyObject, unknown, ''>
        )
      : undefined,
  });

  // Refs
  const inputRef = useRef(null);
  const spanRef = useRef<HTMLSpanElement>(null);

  // Local variables
  const hasError = !isEmpty(errors);
  const watchValue = watch('inputField');
  const customMuiStyles = {
    '.MuiInputBase-root': {
      borderColor: getBorderColor(hasError, isEditMode, canEdit),

      '&.Mui-focused:not(.Mui-error)': {
        borderColor: canEdit ? 'var(--border-default-blue)' : 'transparent',
      },

      '&:hover': {
        borderColor: getHoverBorderColor(hasError, isEditMode, canEdit),
      },
    },
    '.MuiInputBase-input': {
      ...theme.typography[variant as string],
      padding: 'calc(var(--space-4) / 2) var(--space-4)',
      textAlign: 'center',
      textOverflow: overflowEllipsis ? 'ellipsis' : 'unset',
      whiteSpace: overflowEllipsis ? 'nowrap' : 'unset',
      overflow: overflowEllipsis ? 'hidden' : 'unset',
      maxWidth: overflowEllipsis ? '170px' : 'unset',
      color,
    },
  };
  const showPlaceholder =
    !isEditMode && !!placeholder && !watchValue && !hasError;

  // Event handlers
  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    preventClickThrough(event);
    setIsEditMode(true);
  };

  const handleDoubleClick = (event: MouseEvent<HTMLDivElement>) => {
    preventClickThrough(event);

    if (!canEdit) return;

    setIsEditMode(true);
    selectAllText(event.target as HTMLInputElement);
  };

  const handleBlur = async (value: string) => {
    if (!canEdit) return;

    setIsEditMode(false);

    const isValid = await trigger('inputField');

    const trimmedValue = value?.trim();

    if (isValid && trimmedValue !== defaultValue) {
      onSubmit(trimmedValue);
    }
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.target instanceof HTMLInputElement) {
      preventClickThrough(e);

      if (isKeyEnter(e)) {
        setIsEditMode(true);
        e.target.blur();
        return;
      }

      if (e.key === 'Escape') {
        // Passes the escape key press to the onBlur handler
        e.target.setAttribute('data-escape-pressed', 'true');

        setIsEditMode(false);
        e.target.blur();
      }
    }
  };

  useEffect(() => {
    reset({ inputField: defaultValue });
  }, [defaultValue, reset, trigger]);

  useEffect(() => {
    if (
      hasError &&
      errors?.inputField?.message &&
      prevError !== errors?.inputField?.message
    ) {
      // Prevents the error from being shown multiple times
      setPrevError(errors?.inputField?.message as string);

      if (onError) {
        onError(errors?.inputField?.message as string);
      }
    }
  }, [errors?.inputField?.message, hasError, onError, prevError]);

  // Updates the width of the input field to match the width of the text
  useEffect(() => {
    if (spanRef.current && inputRef.current) {
      if (inputRef.current.scrollWidth > inputRef.current.offsetWidth) {
        inputRef.current.style.width = `${inputRef.current.scrollWidth}px`;
      } else {
        inputRef.current.style.width = `${spanRef.current.offsetWidth}px`;
      }
    }
  }, [watchValue]);

  // Sets the input field to be in edit mode when the component is first rendered
  useEffect(() => {
    if (startFromEditMode && inputRef) {
      setIsEditMode(true);
      inputRef.current.focus();
      selectAllText(inputRef.current);
    }
  }, [startFromEditMode]);

  const getSanitizedValue = (value: string) => {
    if (customSanitizer) {
      return customSanitizer(value);
    }

    // Custom logic to prevent multiple spaces from being entered
    return value.replace(/\s{2,}/g, ' ');
  };

  return (
    <div className={cn(styles.container, className)}>
      <span
        style={{ ...theme.typography[variant as string] }}
        className={styles.hide}
        ref={spanRef}
      >
        {showPlaceholder ? placeholder : watchValue}
      </span>

      <Controller
        name="inputField"
        control={control}
        render={({ field }) => (
          <Tooltip arrow title={!canEdit ? t(disabledText) : ''}>
            <TextField
              {...field}
              placeholder={showPlaceholder ? placeholder : undefined}
              inputRef={inputRef}
              variant="outlined"
              onClick={handleClick}
              onDoubleClick={handleDoubleClick}
              onKeyUp={handleKeyUp}
              onChange={(e) => {
                const sanitizedValue = getSanitizedValue(e.target.value);
                setValue('inputField', sanitizedValue);
              }}
              onBlur={(e) => {
                // Prevents the onBlur handler from being called when the escape key is pressed
                if (e.target.getAttribute('data-escape-pressed') === 'true') {
                  e.target.removeAttribute('data-escape-pressed');
                  reset({ inputField: defaultValue });
                  return;
                }

                // Prevents the onBlur handler from being called when the user
                // navigates away from the input field without entered editing mode
                if (isEditMode) {
                  field.onBlur();
                  handleBlur(field.value);
                }
              }}
              sx={customMuiStyles}
              InputProps={{
                readOnly: !isEditMode || !canEdit,
              }}
              error={!!errors?.inputField}
              autoFocus={startFromEditMode}
            />
          </Tooltip>
        )}
      />

      {showError && hasError && (
        <Tooltip
          arrow
          placement="top"
          title={errors?.inputField?.message as string}
          data-testid="error-tooltip"
        >
          <ErrorIcon />
        </Tooltip>
      )}
    </div>
  );
};
