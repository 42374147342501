import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Avatar } from '@/components/atoms/Avatar/Avatar/Avatar';
import Button from '@/components/atoms/Button/Button/Button';
import Info from '@/components/atoms/Icons/Info';
import Input from '@/components/atoms/Input/Input';
import Select from '@/components/atoms/Select/Select';
import useBrains from '@/hooks/useBrains';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import useLanguageModels from '@/modules/developerTools/hooks/useLanguageModels';
import { providerNameMapper } from '@/modules/developerTools/pages/languageModels/helper';
import { ProviderName } from '@/modules/developerTools/types';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectBrainId } from '@/redux/session/selectors';
import { languageAndFlagByCountryCode } from '@/util/languageCodes';
import { capitalizeFirstLetter } from '@/util/util';
import { brainNameSchema } from '@/util/validator';

import { getAIAgentType } from '../../helper';
import { FormCard } from '../FormCard/FormCard';
import { FlagEmoji } from '../TileAIAgent/FlagEmoji';

import styles from './Overview.module.scss';

type Form = {
  name: string;
  provider: string;
  language_model_id: string;
};

export const Overview = () => {
  // Redux selectors
  const brainId = useSelector(selectBrainId);

  // Custom hooks
  const { t } = useTranslation();
  const { brain, updateBrain } = useBrains(brainId);
  const { enabledModels, enabledModelsWithMoveo, languageModel } =
    useLanguageModels(brain?.language_model_id);

  // Local variables
  const {
    language,
    last_trained,
    last_used_at: last_used,
    updated: last_modified,
    created,
    created_by: userId,
    brain_type,
    name,
  } = brain || {};
  const lastTrainedTimeStamp = last_trained
    ? moment(last_trained).fromNow()
    : '-';
  const lastModifiedTimeStamp = last_modified
    ? moment(last_modified).fromNow()
    : '-';
  const badge = brain_type ? (
    <Typography
      component="p"
      color="var(--text-default-blue)"
      variant="label-regular"
      className={styles.type}
    >
      {getAIAgentType(brain_type)}
    </Typography>
  ) : null;
  const llms = enabledModels.length > 0 ? enabledModelsWithMoveo : [];
  const uniqueProviders = uniqBy(llms, 'provider');

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );

  // RHF
  const {
    register,
    formState: { isValid, errors, isDirty, isLoading },
    getValues,
    watch,
    setValue,
  } = useForm<Form>({
    mode: 'onChange',
    values: {
      name: name ?? '',
      provider: languageModel?.provider || 'moveo',
      language_model_id: languageModel?.language_model_id || 'Default',
    },
    resolver: yupResolver(brainNameSchema) as Resolver<Form>,
  });
  const hasSelectedProvider = watch('provider') !== '';

  // Handlers
  const handleSaveForm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const name = getValues('name');
    const language_model_id = getValues('language_model_id');

    if (!isValid) {
      return;
    }

    updateBrain({
      brain_id: brainId,
      name: name.trim(),
      language_model_id:
        language_model_id === 'Default' ? null : language_model_id,
    });
  };

  return (
    <div className={styles.overview}>
      <FormCard>
        <FormCard.Header
          title={t('ai_agents.overview.about_your_ai_agent')}
          icon={<Info backgroundColor="var(--icon-default-blue)" size={18} />}
          badge={badge}
        />
        <FormCard.Content>
          <Input
            size="medium"
            label={t('common.name')}
            placeholder={t('signup.name_placeholder')}
            {...register('name')}
            error={!!errors.name}
            errorMessage={capitalizeFirstLetter(errors.name?.message)}
            disabled={!canWrite}
          />

          {enabledModels?.length > 0 && (
            <Box mt="var(--space-24)" display="flex" gap="var(--space-16)">
              <Select
                size="full"
                name="provider"
                label={t('field.provider')}
                placeholder={t('common.select_an_option')}
                disabled={!canWrite}
                register={register('provider')}
                error={!!errors.provider}
                errorMessage={errors.provider?.message}
                options={uniqueProviders?.map((model) => ({
                  label: providerNameMapper(model.provider as ProviderName),
                  value: model.provider,
                }))}
                onChange={(e) => {
                  const selected = e.target.value;
                  const firstModelOption = uniqueProviders.find(
                    (m) => m.provider === selected
                  );
                  setValue(
                    'language_model_id',
                    firstModelOption?.language_model_id
                  );
                }}
              />

              <Select
                size="full"
                name="language_model_id"
                disabled={!hasSelectedProvider || !canWrite}
                label={t('field.model')}
                placeholder={t('common.select_an_option')}
                register={register('language_model_id')}
                error={!!errors.language_model_id}
                errorMessage={errors.language_model_id?.message}
                options={llms
                  ?.filter((model) => model.provider === watch('provider'))
                  ?.map((model) => ({
                    label: model.model,
                    value: model.language_model_id as string,
                  }))}
              />
            </Box>
          )}

          <div className={styles.infoWrapper}>
            <div className={styles.info}>
              <Typography
                variant="label-caps-large"
                color="var(--text-default-gray)"
              >
                {t('field.language')}
              </Typography>
              <Typography
                display="flex"
                alignItems="center"
                gap="var(--space-4)"
              >
                {language && (
                  <FlagEmoji
                    flag={languageAndFlagByCountryCode[language]?.flag}
                  />
                )}

                {languageAndFlagByCountryCode[language]?.name}
              </Typography>
            </div>
            <Divider orientation="vertical" />
            <div className={styles.info}>
              <Typography
                variant="label-caps-large"
                color="var(--text-default-gray)"
              >
                {t('common.last_used')}
              </Typography>
              <Typography>
                {last_used ? moment(last_used).format('DD-MM-YYYY') : '-'}
              </Typography>
            </div>
            <Divider orientation="vertical" />
            <div className={styles.info}>
              <Typography
                variant="label-caps-large"
                color="var(--text-default-gray)"
              >
                {t('brains.last_trained')}
              </Typography>
              <Typography>{lastTrainedTimeStamp}</Typography>
            </div>
            <Divider orientation="vertical" />
            <div className={styles.info}>
              <Typography
                variant="label-caps-large"
                color="var(--text-default-gray)"
              >
                {t('common.last_modified')}
              </Typography>
              <Typography>{lastModifiedTimeStamp}</Typography>
            </div>
          </div>
        </FormCard.Content>
        <FormCard.Footer>
          <Typography
            variant="label-caps-large"
            display="flex"
            gap="var(--space-4)"
          >
            {t('ai_agents.overview.created_on')}
            <Typography variant="label-regular">
              {moment(created).format('DD-MM-YYYY')}
            </Typography>
            {userId && (
              <>
                {t('common.by')}
                <Avatar userId={userId} size="small" hideStatus />
              </>
            )}
          </Typography>

          <Button
            onClick={handleSaveForm}
            disabled={!(isValid && isDirty)}
            isLoading={isLoading}
            type="submit"
          >
            {t('common.save')}
          </Button>
        </FormCard.Footer>
      </FormCard>
    </div>
  );
};
