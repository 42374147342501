import { Condition } from '@/models/condition';

export const isConditionNested = (obj: Condition): boolean => {
  for (const key of Object.keys(obj)) {
    if (key === 'conditions') {
      for (const k of Object.keys(obj[key])) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj[key][Number(k)].hasOwnProperty('operator')) {
          return true;
        }
      }
    }
  }
  return false;
};
