import { useTranslation } from 'react-i18next';
import { Routes, Route } from 'react-router';
import { useTitle } from 'react-use';

import ErrorBoundary from '@/components/templates/ErrorBoundary';
import RouteElement from '@/components/templates/Routes/RouteElement';

import { PersonalDetailsPage } from './pages/PersonalDetailsPage/PersonalDetailsPage';
import { WelcomePage } from './pages/WelcomePage/WelcomePage';

export const InvitationRouter = () => {
  const { t } = useTranslation();
  useTitle(t('member_invitation.invitation'));

  return (
    <Routes>
      <Route
        path="/details"
        element={
          <ErrorBoundary>
            <PersonalDetailsPage />
          </ErrorBoundary>
        }
      />
      <Route path="/welcome" element={<RouteElement comp={WelcomePage} />} />
    </Routes>
  );
};
