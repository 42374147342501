const Star = ({
  size = 24,
  color = 'var(--icon-default-blue)',
  fill = 'none',
}) => {
  return (
    <svg
      viewBox="0 0 24 24"
      width={size}
      height={size}
      stroke={color}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m12.443 4.083 2.06 4.174 4.607.673-3.333 3.247.786 4.586-4.12-2.166-4.12 2.166.787-4.586L5.777 8.93l4.606-.673 2.06-4.174Z" />
    </svg>
  );
};

export default Star;
