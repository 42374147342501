import { yupResolver } from '@hookform/resolvers/yup';
import IconButton from '@mui/material/IconButton';
import { Resolver, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import Trash from '@/components/atoms/Icons/Trash';
import MarkdownEditor from '@/components/atoms/MarkdownEditor/MarkdownEditor';
import { MODAL_DELETE } from '@/components/organisms/Modals/ModalConductor';
import useBrains from '@/hooks/useBrains';
import { CustomGuideline } from '@/models/brain';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { popModal, pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectBrainId } from '@/redux/session/selectors';
import { capitalizeFirstLetter } from '@/util/util';

import {
  fieldSchema,
  getMaximumCharactersByLanguage,
  MAX_FORM_CHARACTERS,
  sumCurrentCharacters,
} from '../../helper';
import { useTrackFormState } from '../../hooks/useTrackFormState';
import { FormCard } from '../FormCard/FormCard';
import { NumberIcon } from '../NumberIcon/NumberIcon';

type Form = {
  body: string;
};

type CustomGuidelineFormProps = CustomGuideline & {
  order: number;
};

export const CustomGuidelineForm = ({
  title,
  subtitle,
  body,
  created_at,
  order,
}: CustomGuidelineFormProps) => {
  const brainId = useSelector(selectBrainId);
  const { brain, updateBrain } = useBrains(brainId);
  const { language } = brain || {};
  const currentCharacters = sumCurrentCharacters(brain?.guidelines);
  const maxCharacters = getMaximumCharactersByLanguage(language);
  const maxLengthOfBody = maxCharacters - currentCharacters + body.length;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const FORM_ID = `custom-guideline-${order}` as const;

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );

  // RHF
  const {
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<Form>({
    mode: 'onSubmit',
    resolver: yupResolver(
      fieldSchema<Form>('body', MAX_FORM_CHARACTERS, t)
    ) as Resolver<Form>,
    values: {
      body: body || '',
    },
  });

  // Helper function to check if the new body is above the max length
  const isCharactersAboveLimit = (newBody: Form['body']) =>
    newBody.length > maxLengthOfBody;

  // Delete custom guideline based on the created_at
  // because title, subtitle and body can be duplicate
  const deleteCustomGuideline = () => {
    updateBrain({
      brain_id: brainId,
      guidelines: {
        ...brain?.guidelines,
        custom_guidelines: brain?.guidelines?.custom_guidelines?.filter(
          (guideline) => guideline.created_at !== created_at
        ),
      },
    });
  };

  const handleClick = () => {
    const deleteProps = {
      subtitle: (
        <Trans i18nKey="ai_agents.modal.warn_message" values={{ 0: title }} />
      ),
      confirm: true,
      secondaryButtonText: t('common.cancel'),
      onDelete: () => {
        deleteCustomGuideline();
        dispatch(popModal());
      },
    };

    dispatch(pushModal(MODAL_DELETE, deleteProps));
  };

  const onSubmit = (data: Form) => {
    // Display an error message if the new body is above the max length
    if (isCharactersAboveLimit(data.body)) {
      setError('body', {
        type: 'custom',
        message: t('ai_agents.character_limit.error_message'),
      });
      return;
    }

    // Loop all custom guidelines and find the one with the same created_at
    // and update it with the new data
    const customGuidelines = brain?.guidelines?.custom_guidelines || [];
    const index = customGuidelines.findIndex(
      (guideline) => guideline.created_at === created_at
    );

    if (index > -1) {
      customGuidelines[index] = {
        ...customGuidelines[index],
        title,
        subtitle,
        body: data.body,
        created_at,
      };
    } else {
      customGuidelines.push({
        title,
        subtitle,
        body: data.body,
        created_at,
      });
    }

    updateBrain({
      brain_id: brainId,
      guidelines: {
        ...brain?.guidelines,
        custom_guidelines: customGuidelines,
      },
    });
  };

  useTrackFormState({ isDirty, formId: FORM_ID });

  return (
    <FormCard onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
      <FormCard.Header
        title={title}
        subtitle={subtitle}
        icon={
          <NumberIcon
            color="var(--icon-default-blue)"
            size="large"
            number={order}
          />
        }
        badge={
          <IconButton onClick={handleClick} disabled={!canWrite}>
            <Trash />
          </IconButton>
        }
      />

      <FormCard.Content>
        <MarkdownEditor
          name="body"
          setValue={setValue}
          label={t('ai_agents.navigation.overview')}
          defaultValue={body}
          placeholder={t('ai_agents.knowledge.loyalty.placeholder')}
          error={!!errors.body}
          errorMessage={capitalizeFirstLetter(errors.body?.message)}
          disabled={!canWrite}
        />
      </FormCard.Content>

      <FormCard.Footer>
        <Button
          disabled={!isDirty}
          type="submit"
          variant="secondary"
          isLoading={isSubmitting}
        >
          {t('common.save')}
        </Button>
      </FormCard.Footer>
    </FormCard>
  );
};
