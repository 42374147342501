import { useState, useCallback } from 'react';

import mime from 'mime-types';

export const useVoiceMessage = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>();
  const [timerInterval, setTimerInterval] = useState<NodeJS.Timer>();
  const [recordingBlob, setRecordingBlob] = useState<Blob>();
  const [isTranscoding, setIsTranscoding] = useState(false);
  const [totalRecordingTime, setTotalRecordingTime] = useState(0);

  const _startTimer = useCallback(() => {
    const interval = setInterval(() => {
      setRecordingTime((time) => time + 1);
    }, 1000);
    setTimerInterval(interval);
  }, [setRecordingTime, setTimerInterval]);

  const _stopTimer = useCallback(() => {
    timerInterval != null && clearInterval(timerInterval as NodeJS.Timeout);
    setTimerInterval(undefined);
  }, [timerInterval, setTimerInterval]);

  /**
   * Calls express to transcode the audio blob to mp3
   */
  const transcodeToMp3 = async (blob: Blob) => {
    if (!blob) {
      throw new Error('No audio blob');
    }
    const formData = new FormData();
    formData.append('file', blob, `audio.${mime.extension(blob.type)}`);

    // TODO - use one of the already defined methods, callPost or callUpload
    const response = await fetch('/www/media/convert', {
      method: 'POST',
      body: formData,
    });

    return response.blob();
  };

  /**
   * Calling this method would result in the recording to start. Sets `isRecording` to true
   */
  const startRecording = useCallback(async () => {
    if (timerInterval != null) return;

    setRecordingBlob(undefined);
    setTotalRecordingTime(0);

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });
      setIsRecording(true);

      const recorder: MediaRecorder = new MediaRecorder(stream);

      setMediaRecorder(recorder);
      recorder.start();
      _startTimer();

      recorder.addEventListener('dataavailable', async (event) => {
        const recordedBlob = event.data;
        recorder.stream.getTracks().forEach((t) => t.stop());
        setMediaRecorder(undefined);

        try {
          setIsTranscoding(true);
          const transcodedBlob = await transcodeToMp3(recordedBlob);
          setRecordingBlob(transcodedBlob);
          setIsTranscoding(false);
        } catch (transcodingError) {
          console.error('Error during transcoding:', transcodingError);
        }
      });
    } catch (err) {
      console.error(err.name, err.message, err.cause);
    }
  }, [_startTimer, timerInterval]);

  /**
   * Calling this method results in a recording in progress being stopped and the resulting audio being present in `recordingBlob`. Sets `isRecording` to false
   */
  const stopRecording = useCallback(() => {
    mediaRecorder?.stop();
    _stopTimer();
    setTotalRecordingTime(recordingTime);
    setRecordingTime(0);
    setIsRecording(false);
  }, [mediaRecorder, _stopTimer, recordingTime]);

  return {
    startRecording,
    stopRecording,
    recordingBlob,
    setRecordingBlob,
    isRecording,
    recordingTime,
    mediaRecorder,
    isTranscoding,
    totalRecordingTime,
  };
};
