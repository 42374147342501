import { ReactNode, useState } from 'react';

import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { useAccount } from '@/hooks/useAccount';

import styles from './NavigationItem.module.scss';

const style = {
  backgroundColor: 'var(--icon-default-blue)',
  borderRadius: 'var(--border-radius-base)',
};

type Props = {
  icon: ReactNode;
  i18nKey: string;
  submenuItems?: {
    icon: ReactNode;
    title: string;
    link: string;
  }[];
  index: number;
  link?: string;
  isIframe?: boolean;
};

const NavigationItem = ({
  icon,
  i18nKey,
  submenuItems,
  index,
  link,
  isIframe = false,
}: Props) => {
  const { t } = useTranslation();
  const { slug } = useAccount();
  const { pathname } = useLocation();
  const matchingURLS = submenuItems?.map(({ link }) => link);
  const isMatchingURL = matchingURLS?.some((value: string) =>
    pathname?.includes(value)
  );

  const title = t(i18nKey);
  const [open, setOpen] = useState(false);

  const closeSubmenu = () => {
    setOpen(false);
  };

  const openSubmenu = () => {
    setOpen(true);
  };

  return (
    <div
      className={cn(styles.item, {
        [styles.iframe]: isIframe,
      })}
      onMouseEnter={openSubmenu}
      onMouseLeave={closeSubmenu}
      onFocus={openSubmenu}
      onBlur={closeSubmenu}
    >
      <Tooltip
        arrow={isIframe}
        open={open}
        placement={submenuItems ? 'bottom-start' : undefined}
        disableHoverListener={!submenuItems}
        slotProps={{
          popper: {
            modifiers: [{ name: 'offset', options: { offset: [0, 14] } }],
          },
        }}
        PopperProps={
          submenuItems
            ? {
                disablePortal: false,
                sx: {
                  '& .MuiTooltip-tooltip': {
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'var(--surface-primary-white)',
                    border: '0.5px solid var(--border-default-gray)',
                    boxShadow:
                      '0 var(--space-4) var(--space-8) 0 rgba(0, 0, 0, 0.08)',
                    padding: 'var(--space-8)',

                    // We increase specificity to override the default MUI styles
                    '&.MuiTooltip-tooltipPlacementBottom.MuiTooltip-tooltipPlacementBottom':
                      {
                        marginTop: '3px',
                        marginLeft: '-3px',
                      },
                  },
                },
              }
            : isIframe
              ? {
                  sx: {
                    '& .MuiTooltip-arrow': {
                      color: 'var(--iframe-nav-tooltip)',
                    },
                    '& .MuiTooltip-tooltip': {
                      backgroundColor: 'var(--iframe-nav-tooltip)',
                      color: 'var(--iframe-nav-tooltip-text)',
                    },
                  },
                }
              : {}
        }
        title={
          submenuItems ? (
            <>
              <MenuList variant="menu" className={styles.submenu}>
                {submenuItems?.map((item) => {
                  return (
                    <MenuItem className={styles.submenu__item} key={item.link}>
                      <NavLink
                        to={`/${slug}${item.link}`}
                        onClick={closeSubmenu}
                      >
                        {item.icon}

                        <Typography
                          variant="body-regular"
                          color="var(--text-default-black)"
                        >
                          {item.title}
                        </Typography>
                      </NavLink>
                    </MenuItem>
                  );
                })}
              </MenuList>
            </>
          ) : isIframe ? (
            title
          ) : null
        }
      >
        {link ? (
          <NavLink
            end={index === 0}
            style={({ isActive }) => {
              if (isActive || isMatchingURL) {
                return style;
              } else {
                return undefined;
              }
            }}
            to={`/${slug}${link}`}
          >
            {icon}
          </NavLink>
        ) : (
          <div
            tabIndex={0}
            role="button"
            className={cn(styles.pseudoLink, {
              [styles.isActive]: isMatchingURL,
            })}
          >
            {icon}
          </div>
        )}
      </Tooltip>

      {!isIframe && <div className={styles.iconLabel}>{title}</div>}
    </div>
  );
};

export default NavigationItem;
