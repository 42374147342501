import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useBlocker } from 'react-router';

import { MODAL_UNSAVED_CHANGES } from '@/components/organisms/Modals/ModalConductor';
import { pushModal } from '@/redux/modals/actions';

import { selectAnyFormDirty } from '../redux/selectors';

// This hook will block the user from navigating to other routes if any form is dirty
export const useDirtyFormBlocker = () => {
  const dispatch = useDispatch();
  const isAnyFormDirty = useSelector(selectAnyFormDirty);
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isAnyFormDirty && currentLocation.pathname !== nextLocation.pathname
  );

  useEffect(() => {
    if (blocker.state === 'blocked') {
      const modalProps = {
        onPrimaryButtonClick: blocker.reset,
        onSecondaryButtonClick: blocker.proceed,
        onClose: blocker.reset,
      };
      dispatch(pushModal(MODAL_UNSAVED_CHANGES, modalProps));
    }
  }, [blocker, dispatch]);

  return blocker;
};
