import { useCallback, useMemo } from 'react';

import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Edit from '@/components/atoms/Icons/Edit';
import { Power } from '@/components/atoms/Icons/Power';
import Trash from '@/components/atoms/Icons/Trash';
import StatusBadge from '@/components/atoms/StatusBadge/StatusBadge';
import {
  MODAL_LANGUAGE_MODEL_CREATE,
  MODAL_LANGUAGE_MODEL_DELETE,
} from '@/components/organisms/Modals/ModalConductor';
import TileMenu from '@/components/organisms/Tile/TileMenu';
import {
  modelIconResolver,
  modelNameMapper,
} from '@/modules/developerTools/pages/languageModels/helper';
import { pushModal } from '@/redux/modals/actions';

import { Info } from './Info';
interface Props {
  model: LanguageModel;
}
import useLanguageModels from '../../hooks/useLanguageModels';
import { LanguageModel } from '../../types';

import styles from './LanguageModelTile.module.scss';

export const Connected = ({ model }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { updateLanguageModel } = useLanguageModels(model?.language_model_id);

  const handleEditClick = useCallback(() => {
    dispatch(
      pushModal(MODAL_LANGUAGE_MODEL_CREATE, {
        provider: model.provider,
        languageModelId: model.language_model_id,
      })
    );
  }, [dispatch, model.language_model_id, model.provider]);

  const handleEnabledClick = useCallback(() => {
    updateLanguageModel({ enabled: !model.enabled });
  }, [model.enabled, updateLanguageModel]);

  const handleDelete = useCallback(() => {
    dispatch(
      pushModal(MODAL_LANGUAGE_MODEL_DELETE, {
        languageModelId: model.language_model_id,
      })
    );
  }, [dispatch, model.language_model_id]);

  const tileMenuItems = useMemo(
    () => [
      {
        name: t('common.edit'),
        icon: <Edit />,
        onClick: handleEditClick,
      },
      {
        name: model.enabled ? t('common.deactivate') : t('common.activate'),
        icon: <Power />,
        onClick: handleEnabledClick,
      },

      {
        name: t('common.delete'),
        icon: <Trash />,
        type: 'delete',
        disabled: false,
        onClick: handleDelete,
      },
    ],
    [handleDelete, handleEditClick, handleEnabledClick, model.enabled, t]
  );

  return (
    <div className={styles.connected}>
      <span className={styles.logo}>
        {modelIconResolver(model.provider)}
        <Typography variant="subheading-semi-bold">
          {modelNameMapper(model.provider)}
        </Typography>
        {!model?.enabled && (
          <StatusBadge label={t('common.inactive')} variant={'neutral'} />
        )}
        <nav className={styles.menu}>
          <TileMenu menuItems={tileMenuItems} />
        </nav>
      </span>

      <span className={styles.modelName}>
        <Typography
          variant="label-caps-small"
          color="var(--text-default-gray)"
          className={styles.subtitle}
        >
          {t('developer_tools.model')}
        </Typography>
        <Typography
          variant="label-regular"
          color="var(--text-default-gray)"
          className={styles.subtitle}
        >
          {model.model}
        </Typography>
      </span>
      <span className={styles.metadata}>
        <Info lastUsed={model.last_used_at} />
        <Info createdBy={model.created_by} />
      </span>
    </div>
  );
};
