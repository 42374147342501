import { createSelector } from '@reduxjs/toolkit';
import omit from 'lodash/omit';

import { isConditionNested } from '@/components/organisms/Condition/util';

import { Condition } from '../../../ts/models/condition';
import { RootState } from '../../../ts/models/state';

export const keysToOmit = ['isConditionOpen', 'isError', 'isHover'];

export const selectIsConditionOpen = (state: RootState, indexes) => {
  const { outerIndex, index } = indexes;
  const isNested = isConditionNested(state.condition.condition);
  if (isNested) {
    return state.condition.condition.conditions[outerIndex].conditions[index]
      .isConditionOpen;
  } else {
    return state.condition.condition.conditions[index].isConditionOpen;
  }
};

export const selectCondition = createSelector(
  (state: RootState) => state.condition.condition,
  (condition) => omit(condition, keysToOmit) as Condition
);

export const selectIsAnyConiditionOpen = (state: RootState) => {
  const isOpen = (obj) => {
    const result = [];
    const isNested = isConditionNested(obj);
    if (isNested) {
      Object.keys(obj)?.forEach((key) => {
        if (key === 'conditions') {
          obj[key]?.forEach((condition) => {
            condition.conditions.forEach((c) => {
              result.push(c.isConditionOpen);
            });
          });
        }
      });
    } else {
      Object.keys(obj)?.forEach((key) => {
        if (key === 'conditions') {
          obj[key]?.forEach((condition) => {
            result.push(condition.isConditionOpen);
          });
        }
      });
    }
    return result.some((x) => x === true);
  };
  return isOpen(state.condition);
};

export const selectIsConditionError = (state: RootState) => {
  const isError = (obj) => {
    const result = [];
    const isNested = isConditionNested(obj);
    if (isNested) {
      Object.keys(obj)?.forEach((key) => {
        if (key === 'conditions') {
          obj[key]?.forEach((condition) => {
            condition.conditions.forEach((c) => {
              result.push(c.isError);
            });
          });
        }
      });
    } else {
      Object.keys(obj)?.forEach((key) => {
        if (key === 'conditions') {
          obj[key]?.forEach((condition) => result.push(condition.isError));
        }
      });
    }
    return result.some((x) => x === true);
  };
  return isError(state.condition);
};

export const selectConditionHover = (state: RootState) =>
  state.condition.condition.isHover;
