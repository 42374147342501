import { Buffer } from 'buffer';

import { MouseEvent, SyntheticEvent } from 'react';

import i18n from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import set from 'lodash/set';
import moment from 'moment-timezone';
import { UseFormReturn, Path, FieldErrors, PathValue } from 'react-hook-form';
import slugify from 'slugify';
import { v4 as uuidv4 } from 'uuid';

import { callGet } from '@/api/fetcher';
import { Dialogs } from '@/models/dialog';
import { Entities, Entity } from '@/models/entity';
import { Integration } from '@/models/integration';
import { Intent, Intents } from '@/models/intent';
import { Node } from '@/models/node';
import {
  languageCodes,
  countryCodes,
  languageAndFlagByCountryCode,
} from '@/util/languageCodes';

import {
  ACCEPTED_VIDEO_FORMATS,
  CHANNELS_URL,
  MAX_ENDING_URL_CHARS,
  MAX_UNCROPED_WEBHOOK_URL_CHARS,
  THREE_DOTS_LENGTH,
  WEB_CLIENT_URL,
} from './constants';
import { TAG_LENGTH, urlPattern } from './validator';

type Prev = Dialogs | Intents | Entities;

export const noop = () => {};

export const preventClickThrough = (evt: SyntheticEvent) => {
  evt.preventDefault();
  evt.stopPropagation();
};

export const isEventTargetCurrentTarget = (event: SyntheticEvent) =>
  event.target === event.currentTarget;

export const getCarouselPlaceholder = ({ type = 'image', id }) =>
  `https://res.cloudinary.com/dey0ylu2x/image/upload/v1607095100/carousel/${type}${id}.png`;

export const getFlagByLocale = (locale?: string): string | null => {
  if (!locale) {
    return null;
  }
  const code = locale.split('-')[0].toLowerCase();
  return languageAndFlagByCountryCode[code]?.flag || null;
};

export const getLanguageByLocale = (locale?: string): string | null => {
  if (!locale) {
    return null;
  }
  const code = locale.split('-')[0].toLowerCase();
  return languageCodes[code] || null;
};

export const getCountryByCode = (code?: string): string | null => {
  if (!code) {
    return null;
  }
  return countryCodes[code.toLowerCase()]?.name || null;
};

export const isDev = () =>
  window?.location?.hostname === 'localhost' ||
  window?.location?.hostname?.includes('dev') ||
  window?.location?.hostname?.includes('local');

const isGoogleCloudHost = () =>
  window?.location?.hostname?.includes('us-central');

export const previewWidgetUrl = (int: Integration) => {
  if (!int) {
    return null;
  }

  const host = CHANNELS_URL.replace('channels', 'channels-ws');

  // In non-moveo prod environments we need the host in the preview url
  if (!isDev() && !isGoogleCloudHost()) {
    return `${WEB_CLIENT_URL}/preview?integrationId=${int.integration_id}`;
  }

  return `${WEB_CLIENT_URL}/preview?host=${host}&integrationId=${int.integration_id}`;
};

/**
 * Removes entries that have values the empty string
 *
 * @param {object} data redux initial state
 */
export const removeEmptyValues = <T>(data: T): T => {
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(data)) {
    if (value === '') {
      // eslint-disable-next-line no-param-reassign
      delete data[key];
    }
  }
  return data;
};

export const removeNullValues = <valueType>(
  data: Record<string, valueType>
): Record<string, valueType> => {
  for (const [key, value] of Object.entries(data)) {
    if (isNil(value)) {
      delete data[key];
    }
  }
  return data;
};

/**
 * Recursively removes all keys from the given object that have empty object values.
 *
 * @param {Record<string, any>} obj - The object from which to remove keys.
 * @returns {Record<string, any>} - The modified object with empty objects removed.
 */
export function removeEmptyObjects(
  obj: Record<string, unknown>
): Record<string, unknown> {
  Object.keys(obj).forEach((key) => {
    if (
      typeof obj[key] === 'object' &&
      obj[key] !== null &&
      !Array.isArray(obj[key])
    ) {
      removeEmptyObjects(obj[key] as Record<string, unknown>);

      if (Object.keys(obj[key]).length === 0) {
        delete obj[key];
      }
    }
  });
  return obj;
}

// recursive delete function that takes as arguments an object and an array of the dotized path ie. 'p1.p2.p3' to ['p1','p2','p3'] of a null property
// and propagates the null value to the closest parent that has other non-null values or the root property
export const deleteNestedVariables = (object, itemPath: string[]) => {
  const deleted = itemPath.splice(-1)[0];
  const current = itemPath.join('.');
  if (current === '') {
    return { ...object, [deleted]: null };
  }
  const currObj = get(object, current);
  let levelIsNull = true;
  Object.values(currObj).forEach((value) => {
    if (value !== null) {
      levelIsNull = false;
    }
  });
  if (levelIsNull) {
    set(object, current, null);
    return deleteNestedVariables(object, itemPath);
  }
  return object;
};

export const submitWithTrimming = <FormType>(
  formMethods: UseFormReturn<FormType>,
  onSubmit: (data: unknown) => void
) => {
  const submitMiddleware = async (data) => {
    for (const [key] of Object.entries(data)) {
      if (typeof data[key] === 'string') {
        formMethods.setValue(
          key as Path<FormType>,
          data[key].trim() as PathValue<FormType, Path<FormType>>
        );
      }
    }
    const form = formMethods.getValues();
    onSubmit(form);
    formMethods.reset(form);
  };

  const onInvalidSubmit = async (errors: FieldErrors<FormType>) => {
    for (const [key] of Object.entries(errors)) {
      if (typeof errors[key]?.ref?.value === 'string') {
        formMethods.setValue(
          key as Path<FormType>,
          errors[key].ref.value.trim()
        );
      }
    }
    formMethods.handleSubmit(submitMiddleware)();
  };
  return formMethods.handleSubmit(submitMiddleware, onInvalidSubmit);
};

export const sameCollection = (
  category: string,
  categoryId: string,
  queryId: string,
  prev: Prev
) => {
  const deletedCategoryIndex = prev[`${category}`].findIndex(
    (x) => x[`${categoryId}`] === queryId
  );
  const collectionOfDeleted =
    prev[`${category}`][deletedCategoryIndex].collection;
  return prev[`${category}`]
    .filter((x) => x['collection'] === collectionOfDeleted)
    .filter((x) => x[`${categoryId}`] !== queryId);
};

export const validateBase64 = (str: string) => {
  const decoded = Buffer.from(str, 'base64').toString('utf8');
  const encoded = Buffer.from(decoded).toString('base64');
  return str === encoded;
};

export const decodeBase64 = (key: string) => {
  if (typeof key === 'undefined' || key === null) {
    return;
  }
  return Buffer.from(key, 'base64').toString('utf8');
};

export const encodeBase64 = (key: string | Buffer) => {
  if (typeof key === 'undefined' || key === null) {
    return;
  }
  return Buffer.from(key).toString('base64');
};

export const urlSafeBase64Encode = (unencoded: Buffer | string): string => {
  const encoded = encodeBase64(unencoded);
  return encoded.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
};

export const urlSafeBase64Decode = (encoded: string): string => {
  let encodedReplaced = encoded.replace('-', '+').replace('_', '/');
  while (encodedReplaced.length % 4) {
    encodedReplaced += '=';
  }
  return decodeBase64(encodedReplaced);
};

export const getGraphQLSet = (list: string[]) => {
  if (list?.length === 0 || !list) {
    return null;
  }
  const joined = list
    .filter((x) => typeof x === 'string' && x.length > 0)
    .join();

  if (joined.length === 0) {
    return null;
  }
  return `{${joined}}`;
};

export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const selectType = (url: string) => {
  const extension = url.split('.').pop();
  if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {
    return 'image';
  }
  if (extension === 'mp4') {
    return 'video';
  }
  if (
    extension === 'mp3' ||
    extension === 'wav' ||
    extension === 'wma' ||
    extension === 'mpeg'
  ) {
    return 'audio';
  }
  return 'file';
};

export const parseFilter = (filter) => {
  const value = filter[filter.type];
  // compicated structures defined here
  if (
    filter.type === 'deskIds' ||
    filter.type === 'brainIds' ||
    filter.type === 'agentIds'
  ) {
    return getGraphQLSet(value.map((val) => val.value));
  } else if (typeof value === 'object') {
    // filter value type of array
    return getGraphQLSet(value);
  } else if (filter.type === 'maxConfidence') {
    return value / 100;
  } else {
    return value;
  }
};

interface KeyboardEvent<T = Element> extends SyntheticEvent<T> {
  code: string;
  altKey: boolean;
  charCode: number;
  ctrlKey: boolean;
  getModifierState(key: string): boolean;
  key: string;
  keyCode: number;
  locale: string;
  location: number;
  metaKey: boolean;
  repeat: boolean;
  shiftKey: boolean;
  which: number;
}

export const isKeyEnter = (e: KeyboardEvent) => e.key === 'Enter';
export const isKeyTab = (e: KeyboardEvent) => e.key === 'Tab';

/**
 * converts timezones to desired format for Business Hours and Rules
 * @param timezones array of timezones e.g. ["Europe/Budapest"]
 * @returns [{
  label: "(GMT+02:00) Budapest",
  value: "Europe/Budapest"
}]
 */
export function timezoneMaker(timezones: string[]) {
  if (timezones.length === 0) {
    return [];
  }

  return timezones.map((timezone) => {
    const split = timezone.split('/');
    const lastItem = split[split.length - 1];
    const final = lastItem.split(/[-_ ]/).join('_').toLowerCase();
    return {
      label: `(GMT${moment.tz(timezone).format('Z')}) ${i18n.t(final, { ns: 'timezones' })}`,
      value: timezone,
    };
  });
}

/**
 * str => str.startsWith('$') ? str : `$${str}`;
 */
export const checkForDollar = (str: string) =>
  str.startsWith('$') ? str : `$${str}`;

export const numberFormatter = (value: number, shouldRound = true) => {
  if (value < 0 || typeof value !== 'number') {
    return null;
  }

  if (shouldRound && Math.abs(value) > 999 && Math.abs(value) <= 999999) {
    const number = Math.abs(value) / 1000;
    return `${parseFloat(number.toFixed(1))}K`;
  }
  if (shouldRound && Math.abs(value) > 999999) {
    const number = Math.abs(value) / 1000000;

    return `${parseFloat(number.toFixed(1))}M`;
  }
  return Math.sign(value) * Math.abs(value);
};

export const durationFormat = (
  t,
  countMinutesRaw?: number | string,
  defaultValue?: string
) => {
  if (!countMinutesRaw) {
    return defaultValue ?? null;
  }
  let minutes: number;

  if (typeof countMinutesRaw === 'string') {
    minutes = parseFloat(countMinutesRaw);
  } else {
    minutes = countMinutesRaw;
  }
  if (minutes < 0) {
    return null;
  }
  const countHours = Math.floor(minutes / 60);
  const countMinutes = Math.floor(minutes % 60);
  const countSeconds = parseFloat(
    ((minutes - Math.floor(minutes)) * 60).toFixed(2)
  );

  const formatedTime: string[] = [];
  if (countHours > 0) {
    formatedTime.push(`${countHours + t('time.hours')}`);
  }
  if (countMinutes > 0) {
    formatedTime.push(`${countMinutes + t('time.minutes')}`);
  }
  if (countSeconds >= 1) {
    formatedTime.push(`${~~countSeconds + t('time.seconds')}`);
  } else if (formatedTime.length == 0) {
    formatedTime.push(`${countSeconds + t('time.seconds')}`);
  }

  return formatedTime.join(' ');
};

export const capitalizeFirstLetter = (s: string) =>
  (s && s[0].toUpperCase() + s.slice(1)) || '';

/**
 * Removes from a dialog entities and intents that do not exist in the brain.
 *
 * @param newDialog The dialog to be cleared
 * @param intents The brain's intents
 * @param entities The brain's entities
 */
export const clearNewDialog = (
  newDialog: { nodes: Node[] },
  intents: Intent[],
  entities: Entity[]
) => {
  for (const node of newDialog.nodes) {
    if (node.type === 'intent' && node.intent) {
      if (!find(intents, { intent: node.intent })) {
        node.intent = null;
      }
    }
    if (node.conditions) {
      for (const condition of node.conditions) {
        if (condition.rules) {
          for (const rule of condition.rules) {
            if (
              rule?.name &&
              rule.name[0] === '@' &&
              !find(entities, { entity: rule?.name?.replace('@', '') })
            ) {
              rule.name = '';
            }
          }
        }
      }
    }
    if (node.requisites) {
      for (const requisite of node.requisites) {
        if (
          requisite?.check_for &&
          requisite.check_for[0] === '@' &&
          !find(entities, {
            entity: requisite?.check_for?.replace('@', ''),
          })
        ) {
          requisite.check_for = '';
        }
      }
    }
  }
};

export const selectSingularPlural = (
  singular: string,
  plural: string,
  isSingular: boolean
): string => (isSingular ? singular : plural);

export const isPromise = (promise) =>
  !!promise && typeof promise.then === 'function';

export const getWebviewsUrl = () =>
  isDev() ? 'https://webviews.dev.moveo.ai' : 'https://webviews.moveo.ai';

const VARIABLES_FOR_SLOW_QUERIES = [
  'minNumUserMessages',
  'channels',
  'integrationIds',
  'brainVersions',
  'agentIds',
];

/**
 * Returns true if the analytics query to use should be based on the old/slow model.
 * New/Fast queries are based on the presence of a timescale database.
 */
export const shouldUseSlowQueries = (
  variables,
  options?: { is_brain_effectiveness?: boolean }
): boolean => {
  // Based on a environment variable from the server
  // Disable fast queries for environments where the backend does not have
  // a timescale instance
  const disableFastQueries = window.ANALYTICS_DISABLE_FAST_QUERIES;
  if (disableFastQueries === 'true') {
    return true;
  }
  // Check the variables used in the query
  const usedVariables = Object.keys(variables);
  if (VARIABLES_FOR_SLOW_QUERIES.some((sv) => usedVariables.includes(sv))) {
    return true;
  }

  if (options?.is_brain_effectiveness && 'isTest' in variables) {
    return true;
  }

  return false;
};

export const getMedian = (array: number[], minimum = 0) => {
  let value = minimum;

  if (!array || !array.length) {
    return value;
  }
  const sorted = array.slice().sort((a, b) => a - b);
  const middle = Math.floor(sorted.length / 2);

  if (sorted.length % 2 === 0) {
    value = (sorted[middle - 1] + sorted[middle]) / 2;
  } else {
    value = sorted[middle];
  }
  return value < minimum ? minimum : value;
};

export const isUrl = (url) => urlPattern.test(url);

/**
 * Returns a new array of unique objects from an input array of objects, based on a specified key.
 *
 * @param {Array} arr - The input array of objects.
 * @param {string} key - The key to use for uniqueness comparison.
 * @returns {Array} - A new array of unique objects.
 */
export const getUniqueListBy = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

/**
 * Removes all the occurrences of the '$' symbol from a string.
 */
export const removeDollarSign = (input: string) =>
  (input || '').replace(/\$/g, '');

/**
 * Removes all the occurrences of the '@' symbol from a string.
 */
export const removeAtSign = (input: string) => (input || '').replace(/@/g, '');

/**
 * Adds a dollar sign to the beginning of a string if it doesn't already have one.
 */
export const addDollarSign = (input: string) => {
  if (input?.startsWith('$') || input === '') {
    return input;
  } else {
    return '$' + input;
  }
};

/**
 * Wraps words that start with a dollar sign with curly braces.
 *
 * @param {string} input - The input string to be processed.
 * @returns {string} The input string with all words starting with a dollar sign wrapped with curly braces.
 */
export const wrapDollarSignWithCurlyBraces = (input: string): string => {
  const regex = /\$[\w\d]+((\.|-)[\w\d]+)?/g; // Matches words starting with $ and optional .word part

  if (regex.test(input)) {
    const wrapped = input.replace(regex, '{{$&}}'); // Wrap all matched dollar signs with curly braces
    return wrapped;
  } else {
    return input; // If there is no dollar sign, return the original string
  }
};

/**
 * Reverts the wrapping of words that start with a dollar sign with curly braces.
 *
 * @param {string} input - The input string to be processed.
 * @returns {string} The input string with all words wrapped with curly braces and a dollar sign replaced with the dollar sign only.
 */
export const revertCurlyBraceWrappedDollarSigns = (input: string): string => {
  const regex = /{\$[\w\d]+}/g; // The regular expression to match words wrapped with curly braces and a dollar sign

  if (regex.test(input)) {
    const reverted = input.replace(/{{(\$\w+)}}/g, '$1'); // Keep only the dollar sign word and remove the curly braces
    return reverted;
  } else {
    return input; // If there is no word wrapped with curly braces and a dollar sign, return the original string
  }
};
export function isVideo(url: string) {
  if (!url) {
    return false;
  }

  const extensionPattern = /\.([0-9a-z]+)(?=[?#]|$)/i;
  const extension = url.match(extensionPattern);

  if (!extension) {
    return false;
  }

  for (const format in ACCEPTED_VIDEO_FORMATS) {
    if (ACCEPTED_VIDEO_FORMATS[format].includes(extension[0].toLowerCase())) {
      return true;
    }
  }

  return false;
}

/**
 * Checks if the event is a left-click event.
 *
 * @param {MouseEvent} event - The event object to be checked.
 * @returns {boolean} Returns `true` if the event is a left-click event (button value is 0), otherwise `false`.
 */
export const isLeftClickEvent = (event: MouseEvent) => {
  return event?.button === 0;
};

const ids = ['node_id', 'action_id', 'condition_id'];

/**
 * This function updates the IDs of an object or an array of objects.
 * It traverses the object or array and replaces any ID with a new UUID.
 *
 * @export
 * @param {Object|Array} obj - The object or array of objects to update.
 * @returns {Object|Array} The updated object or array of objects with new UUIDs.
 */
export function updateIds(obj) {
  const clonedObj = cloneDeep(obj);
  function traverse(o) {
    for (const i in o) {
      if (typeof o[i] === 'object' && o[i] !== null) {
        if (ids.includes(i)) {
          o[i] = uuidv4();
        }
        traverse(o[i]);
      } else if (Array.isArray(o[i])) {
        o[i].forEach((item) => {
          traverse(item);
        });
      } else {
        if (ids.includes(i)) {
          o[i] = uuidv4();
        }
      }
    }
  }

  traverse(clonedObj);
  return clonedObj;
}

export const commonSymbolsRegex =
  /^[a-zA-Z0-9\s!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]+$/;

export const renderCroppedURL = (
  url?: string,
  maxVisible = MAX_UNCROPED_WEBHOOK_URL_CHARS,
  maxEnding = MAX_ENDING_URL_CHARS
) => {
  if (!url) {
    return '';
  }

  if (url.length <= maxVisible) {
    return url;
  }

  let formattedUrl = url;
  if (url[url.length - 1] === '/') {
    formattedUrl = url.slice(0, -1);
  }

  const urlParts = formattedUrl.split('/');
  const finalUrlPart = urlParts[urlParts.length - 1];

  const endingURLCharacters: number = Math.min(finalUrlPart.length, maxEnding);
  const startingURLCharacters =
    maxVisible - THREE_DOTS_LENGTH - endingURLCharacters;

  const croppedURL = `${formattedUrl.slice(
    0,
    startingURLCharacters
  )}.../${formattedUrl.slice(-endingURLCharacters)}`;

  return croppedURL;
};

export const calculateDaysBetweenDates = (
  startDate: string,
  endDate?: string
) => {
  const start = moment(startDate);
  const end = moment(endDate);
  const duration = end.diff(start, 'days');
  return duration;
};

export const bytesToSize = (bytes: number) => {
  if (!bytes || bytes < 0) {
    return '-';
  }
  const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  if (i === 0) {
    return `${bytes} ${sizes[i]}`;
  }
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

/**
 * Finds and returns the first object in an array where one of the object's property values matches the given value.
 *
 * @param {object[]} array - The array of objects to search.
 * @param {string|number} valueToFind - The value (string or number) to match against the object's properties.
 * @returns {object|null} The first object that contains a property with the given value, or null if not found.
 *
 * @example
 * const data = [{a: 1, b: 2}, {a: 3, b: 4}, {a: 5, b: 2}];
 * findObjectByValue(data, 4); // Returns: {a: 3, b: 4}
 * findObjectByValue(data, 6); // Returns: null
 */
export const findObjectByValue = (
  array: Array<object>,
  valueToFind: string | number
) => {
  // Iterate through the array of objects
  for (let i = 0; i < array.length; i++) {
    // Get the current object from the array
    const obj = array[i];

    // Iterate through each property in the object
    for (const key in obj) {
      // If the property value matches the input string, return the object
      if (obj[key] === valueToFind) {
        return obj;
      }
    }
  }

  // If no match is found, return null
  return null;
};

/**
 * Returns an array of names from the provided items, excluding the specified name.
 *
 * @param {Array<unknown>} items - The array of items to extract names from.
 * @param {string} excludeName - The name to exclude from the returned array.
 * @param {string} [nameKey='name'] - The key to use to extract the name from each item. Defaults to 'name'.
 * @returns {Array<string>} An array of names, excluding the specified name.
 *
 * @example
 * const data = [{name: 'John', age: 30}, {name: 'Jane', age: 25}, {name: 'Joe', age: 35}];
 * getRestrictedNames(data, 'Jane'); // Returns: ['John', 'Joe']
 * getRestrictedNames(data, 'John', 'name'); // Returns: ['Jane', 'Joe']
 */
export const getRestrictedNames = (
  items: Array<unknown>,
  excludeName: string,
  nameKey = 'name'
) => {
  if (!items || !excludeName) {
    return [];
  }

  return (
    items
      ?.map((item) => item[nameKey])
      .filter((name) => name.toLowerCase() !== excludeName.toLowerCase()) ?? []
  );
};

/**
 * Generates the next name for a given item in a list of items with a common base name.
 */
export const generateNextName = (
  items: { name: string }[] = [],
  baseName: string
): string => {
  const regex = new RegExp(`${baseName} (\\d+)$`, 'i');

  let maxNumber = -1;
  let baseNameExists = false;

  for (const item of items) {
    if (item.name.toLowerCase() === baseName.toLowerCase()) {
      baseNameExists = true;
    }
    const match = item.name.match(regex);
    if (match) {
      const currentNumber = parseInt(match[1], 10);
      maxNumber = Math.max(maxNumber, currentNumber);
    }
  }

  if (maxNumber === -1 && !baseNameExists) {
    return baseName;
  }

  if (maxNumber === -1 && baseNameExists) {
    return `${baseName} 1`; // if only baseName exists
  }

  return `${baseName} ${maxNumber + 1}`;
};

export const emojiFlag = (
  countryCode: string,
  countryFlagData: { [key: string]: string } = {
    AD: '🇦🇩',
    AE: '🇦🇪',
    AF: '🇦🇫',
    AG: '🇦🇬',
    AI: '🇦🇮',
    AL: '🇦🇱',
    AM: '🇦🇲',
    AO: '🇦🇴',
    AQ: '🇦🇶',
    AR: '🇦🇷',
    AS: '🇦🇸',
    AT: '🇦🇹',
    AU: '🇦🇺',
    AW: '🇦🇼',
    AX: '🇦🇽',
    AZ: '🇦🇿',
    BA: '🇧🇦',
    BB: '🇧🇧',
    BD: '🇧🇩',
    BE: '🇧🇪',
    BF: '🇧🇫',
    BG: '🇧🇬',
    BH: '🇧🇭',
    BI: '🇧🇮',
    BJ: '🇧🇯',
    BL: '🇧🇱',
    BM: '🇧🇲',
    BN: '🇧🇳',
    BO: '🇧🇴',
    BQ: '🇧🇶',
    BR: '🇧🇷',
    BS: '🇧🇸',
    BT: '🇧🇹',
    BV: '🇧🇻',
    BW: '🇧🇼',
    BY: '🇧🇾',
    BZ: '🇧🇿',
    CA: '🇨🇦',
    CC: '🇨🇨',
    CD: '🇨🇩',
    CF: '🇨🇫',
    CG: '🇨🇬',
    CH: '🇨🇭',
    CI: '🇨🇮',
    CK: '🇨🇰',
    CL: '🇨🇱',
    CM: '🇨🇲',
    CN: '🇨🇳',
    CO: '🇨🇴',
    CR: '🇨🇷',
    CU: '🇨🇺',
    CV: '🇨🇻',
    CW: '🇨🇼',
    CX: '🇨🇽',
    CY: '🇨🇾',
    CZ: '🇨🇿',
    DE: '🇩🇪',
    DJ: '🇩🇯',
    DK: '🇩🇰',
    DM: '🇩🇲',
    DO: '🇩🇴',
    DZ: '🇩🇿',
    EC: '🇪🇨',
    EE: '🇪🇪',
    EG: '🇪🇬',
    EH: '🇪🇭',
    ER: '🇪🇷',
    ES: '🇪🇸',
    ET: '🇪🇹',
    FI: '🇫🇮',
    FJ: '🇫🇯',
    FK: '🇫🇰',
    FM: '🇫🇲',
    FO: '🇫🇴',
    FR: '🇫🇷',
    GA: '🇬🇦',
    GB: '🇬🇧',
    GD: '🇬🇩',
    GE: '🇬🇪',
    GF: '🇬🇫',
    GG: '🇬🇬',
    GH: '🇬🇭',
    GI: '🇬🇮',
    GL: '🇬🇱',
    GM: '🇬🇲',
    GN: '🇬🇳',
    GP: '🇬🇵',
    GQ: '🇬🇶',
    GR: '🇬🇷',
    GS: '🇬🇸',
    GT: '🇬🇹',
    GU: '🇬🇺',
    GW: '🇬🇼',
    GY: '🇬🇾',
    HK: '🇭🇰',
    HM: '🇭🇲',
    HN: '🇭🇳',
    HR: '🇭🇷',
    HT: '🇭🇹',
    HU: '🇭🇺',
    ID: '🇮🇩',
    IE: '🇮🇪',
    IL: '🇮🇱',
    IM: '🇮🇲',
    IN: '🇮🇳',
    IO: '🇮🇴',
    IQ: '🇮🇶',
    IR: '🇮🇷',
    IS: '🇮🇸',
    IT: '🇮🇹',
    JE: '🇯🇪',
    JM: '🇯🇲',
    JO: '🇯🇴',
    JP: '🇯🇵',
    KE: '🇰🇪',
    KG: '🇰🇬',
    KH: '🇰🇭',
    KI: '🇰🇮',
    KM: '🇰🇲',
    KN: '🇰🇳',
    KP: '🇰🇵',
    KR: '🇰🇷',
    KW: '🇰🇼',
    KY: '🇰🇾',
    KZ: '🇰🇿',
    LA: '🇱🇦',
    LB: '🇱🇧',
    LC: '🇱🇨',
    LI: '🇱🇮',
    LK: '🇱🇰',
    LR: '🇱🇷',
    LS: '🇱🇸',
    LT: '🇱🇹',
    LU: '🇱🇺',
    LV: '🇱🇻',
    LY: '🇱🇾',
    MA: '🇲🇦',
    MC: '🇲🇨',
    MD: '🇲🇩',
    ME: '🇲🇪',
    MF: '🇲🇫',
    MG: '🇲🇬',
    MH: '🇲🇭',
    MK: '🇲🇰',
    ML: '🇲🇱',
    MM: '🇲🇲',
    MN: '🇲🇳',
    MO: '🇲🇴',
    MP: '🇲🇵',
    MQ: '🇲🇶',
    MR: '🇲🇷',
    MS: '🇲🇸',
    MT: '🇲🇹',
    MU: '🇲🇺',
    MV: '🇲🇻',
    MW: '🇲🇼',
    MX: '🇲🇽',
    MY: '🇲🇾',
    MZ: '🇲🇿',
    NA: '🇳🇦',
    NC: '🇳🇨',
    NE: '🇳🇪',
    NF: '🇳🇫',
    NG: '🇳🇬',
    NI: '🇳🇮',
    NL: '🇳🇱',
    NO: '🇳🇴',
    NP: '🇳🇵',
    NR: '🇳🇷',
    NU: '🇳🇺',
    NZ: '🇳🇿',
    OM: '🇴🇲',
    PA: '🇵🇦',
    PE: '🇵🇪',
    PF: '🇵🇫',
    PG: '🇵🇬',
    PH: '🇵🇭',
    PK: '🇵🇰',
    PL: '🇵🇱',
    PM: '🇵🇲',
    PN: '🇵🇳',
    PR: '🇵🇷',
    PS: '🇵🇸',
    PT: '🇵🇹',
    PW: '🇵🇼',
    PY: '🇵🇾',
    QA: '🇶🇦',
    RE: '🇷🇪',
    RO: '🇷🇴',
    RS: '🇷🇸',
    RU: '🇷🇺',
    RW: '🇷🇼',
    SA: '🇸🇦',
    SB: '🇸🇧',
    SC: '🇸🇨',
    SD: '🇸🇩',
    SE: '🇸🇪',
    SG: '🇸🇬',
    SH: '🇸🇭',
    SI: '🇸🇮',
    SJ: '🇸🇯',
    SK: '🇸🇰',
    SL: '🇸🇱',
    SM: '🇸🇲',
    SN: '🇸🇳',
    SO: '🇸🇴',
    SR: '🇸🇷',
    SS: '🇸🇸',
    ST: '🇸🇹',
    SV: '🇸🇻',
    SX: '🇸🇽',
    SY: '🇸🇾',
    SZ: '🇸🇿',
    TC: '🇹🇨',
    TD: '🇹🇩',
    TF: '🇹🇫',
    TG: '🇹🇬',
    TH: '🇹🇭',
    TJ: '🇹🇯',
    TK: '🇹🇰',
    TL: '🇹🇱',
    TM: '🇹🇲',
    TN: '🇹🇳',
    TO: '🇹🇴',
    TR: '🇹🇷',
    TT: '🇹🇹',
    TV: '🇹🇻',
    TW: '🇹🇼',
    TZ: '🇹🇿',
    UA: '🇺🇦',
    UG: '🇺🇬',
    UM: '🇺🇲',
    US: '🇺🇸',
    UY: '🇺🇾',
    UZ: '🇺🇿',
    VA: '🇻🇦',
    VC: '🇻🇨',
    VE: '🇻🇪',
    VG: '🇻🇬',
    VI: '🇻🇮',
    VN: '🇻🇳',
    VU: '🇻🇺',
    WF: '🇼🇫',
    WS: '🇼🇸',
    XK: '🇽🇰',
    YE: '🇾🇪',
    YT: '🇾🇹',
    ZA: '🇿🇦',
    ZM: '🇿🇲',
  },
  fallback: string = '🏳'
) => {
  if (!countryCode) {
    return '';
  }
  if (countryCode.toLowerCase().includes('en')) {
    return countryFlagData['US'];
  }
  if (countryCode.toLowerCase().includes('br')) {
    return countryFlagData['BR'];
  }
  const arr = countryCode.toUpperCase().split('-');
  return countryFlagData[(arr[1] || arr[0]).toUpperCase()] || fallback;
};

export const convertNameToTag = (str: string) => {
  if (!str) {
    return null;
  }
  return slugify(str, {
    replacement: '_', // Replace spaces with hyphens
    remove: /[^\w\s-]/g, // Remove all non-alphanumeric and non-hyphen characters
    lower: true, // Convert to lowercase
    strict: true, // Enforce strict mode
  }).slice(0, TAG_LENGTH);
};

export function scrollToElementById(elementId: string) {
  const element = document.getElementById(elementId);

  if (!element) {
    return;
  }

  element.scrollIntoView({
    behavior: 'smooth',
  });
}

/**
 * Checks if the email is valid to create an user, checking if is it personal or disposable
 *@param {string} email - email address to check
 *@return {Promise<boolean>} resolves to true if the email is valid
 */
export const isValidEmail = async (email: string): Promise<boolean> => {
  return callGet(
    `/www/api/auth/validate-email/?email=${encodeURIComponent(email)}`
  ).then((data) => data.valid);
};
/**
 * Decodes HTML entities in a string into their corresponding characters.
 *
 * This function creates a temporary DOM element (textarea), assigns the
 * provided text to its `innerHTML`, and then retrieves the decoded value
 * from the element's `value` property. This effectively converts HTML
 * entity codes (e.g., `&amp;`, `&#39;`) to their respective characters (e.g., `&`, `'`).
 *
 * @param {string} text - The string containing HTML entities that need to be decoded.
 * @returns {string} - The decoded string with HTML entities converted to their corresponding characters.
 *
 * @example
 * const decodedText = decodeHTMLEntities("I&#39;m transferring ...");
 * console.log(decodedText); // Outputs: I'm transferring ...
 */
export const decodeHTMLEntities = (text: string) => {
  const txt = document.createElement('textarea');
  txt.innerHTML = text;
  return txt.value;
};

/**
 * Splits an array into chunks of specified size.
 *
 * @template T
 * @param {T[]} items - The array to split.
 * @param {number} size - The size of each chunk.
 * @returns {T[][]} - The array split into chunks.
 */
export const splitArray = <T>(items: T[], size: number): T[][] => {
  return items.reduce((resultArray: T[][], item, index) => {
    const chunkIndex = Math.floor(index / size);
    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }
    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);
};

/**
 * Resolves a path by conditionally transforming it based on the ai_agents flag.
 *
 * @param {string} path - The original path to transform.
 * @param {boolean} ai_agents - Flag to determine if path should be transformed for AI agents.
 * @returns {string} - The transformed path if ai_agents is true, otherwise the original path.
 */
export const resolveBrainsPath = (path: string, ai_agents: boolean): string => {
  if (ai_agents) {
    return (
      path
        // Replace brains with ai-agents
        .replace('/brains', '/ai-agents')
        // Add /conversation to intents, dialogs, webhooks and entities
        .replace(
          /(\/intents|\/dialogs|\/webhooks|\/entities)/,
          '/conversation$1'
        )
        // Replace /logs and /versions with /review/logs and /review/versions
        .replace(/\/(logs|versions)/, '/review/$1')
        // Replace /collections with /knowledge
        .replace('/collections', '/knowledge')
    );
  }

  return path;
};
