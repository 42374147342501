import React from 'react';

import cn from 'classnames';
import { useSelector } from 'react-redux';

import Brain from '@/components/atoms/Icons/Brain';
import Collection from '@/components/atoms/Icons/Collection';
import Environment from '@/components/atoms/Icons/Environment';
import Home from '@/components/atoms/Icons/Home';
import Integrations from '@/components/atoms/Icons/Integrations';
import Rules from '@/components/atoms/Icons/Rules';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { IconBroadcast } from '@/modules/broadcast/icons/Broadcast';
import { selectDeskId } from '@/redux/session/selectors';
import { resolveBrainsPath } from '@/util/util';

import NavigationItem from '../NavigationItem/NavigationItem';

import styles from './NavigationIframe.module.scss';

interface itemProps {
  title: string;
  path: string;
  icon: React.ReactNode;
}

const MainNavigationIframe = () => {
  const deskId = useSelector(selectDeskId);
  const { ai_agents } = useFeatureFlag();
  const navigationItems: Array<itemProps> = [
    { title: 'main_navigation.home', path: '/', icon: <Home /> },
    {
      title: ai_agents ? 'common.ai_agents' : 'common.brains',
      path: resolveBrainsPath('/brains', ai_agents),
      icon: <Brain />,
    },
    {
      title: 'main_navigation.collections',
      path: '/collections',
      icon: <Collection color="currentColor" size={16} />,
    },
    {
      title: 'main_navigation.broadcast_other',
      path: '/broadcasts',
      icon: <IconBroadcast />,
    },
    {
      title: 'main_navigation.integrations',
      path: `/environments/${deskId}/integrations`,
      icon: <Integrations color="currentColor" />,
    },
    {
      title: 'main_navigation.rules',
      path: `/environments/${deskId}/rules`,
      icon: <Rules color="currentColor" size={16} />,
    },
    {
      title: 'main_navigation.environments',
      path: `/environments/${deskId}/settings`,
      icon: <Environment size={16} />,
    },
  ];

  return (
    <nav className={cn(styles.navbar)}>
      <ul className={styles.navigationList}>
        {navigationItems.map((item, index) => {
          return (
            <NavigationItem
              key={item.title}
              i18nKey={item.title}
              index={index}
              icon={item.icon}
              link={item.path}
              isIframe={true}
            />
          );
        })}
      </ul>
    </nav>
  );
};

export default MainNavigationIframe;
