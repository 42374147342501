import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router';

import Header from '@/components/organisms/Header/Header';
import Layout from '@/components/templates/Layout/Layout';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { LanguageModels } from '@/modules/developerTools//pages/languageModels/LanguageModels';
import { APIKeys } from '@/modules/developerTools/pages/APIKeys/APIKeys';
import { selectAccountSlug } from '@/redux/session/selectors';

import { Wrapper } from './pages/Wrapper';

import styles from './DeveloperToolsRouter.module.scss';

function a11yProps(index: number) {
  return {
    id: `devtools-tab-${index}`,
    'aria-controls': `devtools-tabpanel-${index}`,
  };
}

export const DeveloperToolsRouter = () => {
  // Custom hooks
  const { t } = useTranslation();
  const features = useFeatureFlag();

  const location = useLocation();
  const navigate = useNavigate();
  const slug = useSelector(selectAccountSlug);

  // Local variables
  const initialTabValue = location.pathname.endsWith('/models') ? 1 : 0;

  // Local state
  const [tabValue, setTabValue] = useState(initialTabValue);

  // Handlers
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    const newPath = `/${slug}/devtools/${newValue === 0 ? 'apikeys' : 'models'}`;
    navigate(newPath);
    setTabValue(newValue);
  };

  useEffect(() => {
    // Keep the tab value in sync with the URL
    if (initialTabValue !== tabValue) {
      setTabValue(initialTabValue);
    }
  }, [initialTabValue, tabValue]);

  useEffect(() => {
    // Redirect to the first tab if the user tries to access the devtools page directly
    if (location.pathname.endsWith('/devtools')) {
      navigate(`/${slug}/devtools/apikeys`);
    }
  }, [location.pathname, navigate, slug]);

  return (
    <Layout>
      <Header>
        <Header.Title
          title={
            <Typography variant="h2-semi-bold" color="var(--text-default-gray)">
              {t('developer_tools.title')}
            </Typography>
          }
        />
        <Box mt={0.5}>
          <Header.Navigation>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label={t('developer_tools.title')}
            >
              <Tab label={t('developer_tools.api_keys')} {...a11yProps(0)} />
              {features?.ai_agents && (
                <Tab label={t('developer_tools.models')} {...a11yProps(1)} />
              )}
            </Tabs>
          </Header.Navigation>
        </Box>
      </Header>

      <div className={styles.wrapper}>
        <Routes>
          <Route
            path="/apikeys"
            element={
              <Wrapper thisTab={0} tabValue={tabValue} module="credentials">
                <APIKeys />
              </Wrapper>
            }
          />
          {features?.ai_agents && (
            <Route
              path="/models"
              element={
                <Wrapper
                  thisTab={1}
                  tabValue={tabValue}
                  module="language_models"
                >
                  <LanguageModels />
                </Wrapper>
              }
            />
          )}
        </Routes>
      </div>
    </Layout>
  );
};
