import { BoldPlugin, ItalicPlugin } from '@udecode/plate-basic-marks/react';
import { useEditorReadOnly } from '@udecode/plate-common/react';
import {
  BulletedListPlugin,
  NumberedListPlugin,
} from '@udecode/plate-list/react';
import { BoldIcon, ItalicIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { EmojiDropdownMenu } from './emoji-dropdown-menu';
import { LinkToolbarButton } from './link-toolbar-button';
import { ListToolbarButton } from './list-toolbar-button';
import { MarkToolbarButton } from './mark-toolbar-button';
import { ToolbarGroup } from './toolbar';

export function FixedToolbarButtons() {
  const readOnly = useEditorReadOnly();

  const { t } = useTranslation();

  return (
    <div className="flex w-full">
      {!readOnly && (
        <>
          <ToolbarGroup>
            <MarkToolbarButton
              nodeType={BoldPlugin.key}
              tooltip={`${t('rich_text_editor.bold')} (⌘+B)`}
            >
              <BoldIcon />
            </MarkToolbarButton>

            <MarkToolbarButton
              nodeType={ItalicPlugin.key}
              tooltip={`${t('rich_text_editor.italic')} (⌘+I)`}
            >
              <ItalicIcon />
            </MarkToolbarButton>
          </ToolbarGroup>

          <ToolbarGroup>
            <ListToolbarButton nodeType={BulletedListPlugin.key} />
            <ListToolbarButton nodeType={NumberedListPlugin.key} />
          </ToolbarGroup>

          <ToolbarGroup>
            <LinkToolbarButton />
            <EmojiDropdownMenu />
          </ToolbarGroup>
        </>
      )}
    </div>
  );
}
