import { useEffect } from 'react';

import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { clearCondition } from '@/redux/condition/actions';
import { selectCondition } from '@/redux/condition/selectors';

import ConditionGroup from './ConditionGroup';
import { isConditionNested } from './util';

import styles from './Condition.module.scss';

const Condition = () => {
  const _condition = useSelector(selectCondition);
  const isNested = isConditionNested(_condition);
  const dispatch = useDispatch();

  useEffect(() => {
    // Clear state when component unmounts
    return () => {
      dispatch(clearCondition());
    };
  }, [dispatch]);

  if (!isNested) {
    return (
      <div
        className={cn(styles.conditionWrapper, {
          [styles.empty]: !_condition?.conditions.length,
        })}
      >
        <div className={styles.rule}>
          <ConditionGroup singleCondition={_condition} outerIndex={0} />
        </div>
      </div>
    );
  }

  if (isNested) {
    return (
      <div
        className={cn(styles.conditionWrapper, {
          [styles.empty]: !_condition?.conditions.length,
        })}
      >
        {_condition.conditions.map((condition, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className={styles.conditionInnerWrapper} key={index}>
            <div className={styles.rule}>
              <ConditionGroup singleCondition={condition} outerIndex={index} />
            </div>
          </div>
        ))}
      </div>
    );
  }
};

export default Condition;
