/**
 * A custom React hook that manages form state in Redux
 * - Tracks whether a form is "dirty" (has unsaved changes) in Redux state
 * - When isDirty changes, updates the Redux store with the form's dirty state
 * - On unmount, cleans up the form state from Redux if the form was dirty
 *
 * @param {Object} params
 * @param {boolean} params.isDirty - Whether the form has unsaved changes
 * @param {string} params.formId - Unique identifier for the form
 */
import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { removeFormState, updateFormDirtyState } from '../redux/actions';

type Props = {
  isDirty: boolean;
  formId: string;
};

export const useTrackFormState = ({ isDirty, formId }: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Update Redux state whenever `isDirty` changes
    dispatch(updateFormDirtyState({ formId, isDirty }));

    return () => {
      if (isDirty) {
        // Clean up form state on unmount
        dispatch(removeFormState({ formId }));
      }
    };
  }, [isDirty, dispatch, formId]);
};
