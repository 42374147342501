import { memo } from 'react';

import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { RoleBadge } from '../RoleBadge/RoleBadge';

import styles from './MemberBadge.module.scss';

type MemberBadgeProps = {
  accountAvatarUrl: string;
  name: string;
  roles: string[];
  accountName: string;
};

const Avatar = ({
  accountAvatarUrl,
  accountName,
}: {
  accountAvatarUrl: string;
  accountName: string;
}) => {
  const { t } = useTranslation();
  return (
    <img
      className={styles.badge__avatar}
      src={accountAvatarUrl}
      alt={`${accountName} ${t('common.logo')}`}
      width="64"
      height="64"
    />
  );
};

const Name = ({ name }: { name: string }) => (
  <Typography
    variant="h2-semi-bold"
    component="h2"
    color="var(--text-default-black)"
    mb="var(--space-16)"
  >
    {name}
  </Typography>
);

const Roles = ({ roles }: { roles: string[] }) => (
  <div className={styles.badge__roles}>
    {roles?.map((role) => <RoleBadge key={role} role={role} />)}
  </div>
);

const AccountName = ({ accountName }: { accountName: string }) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography
        variant="label-caps-large"
        color="var(--text-default-gray-light)"
        component="h3"
        mb="var(--space-8)"
      >
        {t('common.account')}
      </Typography>
      <Typography
        component="p"
        variant="subheading-bold"
        color="var(--text-default-black)"
        mb="var(--space-40)"
      >
        {accountName}
      </Typography>
    </>
  );
};

const Logo = () => {
  const { t } = useTranslation();
  return (
    <img
      src="/assets/memberInvitation/moveo_ai_120_20.svg"
      alt={`MOVEO.AI ${t('common.logo')}`}
      width="77"
      height="14"
    />
  );
};

export const MemberBadge = ({
  accountAvatarUrl,
  name,
  roles,
  accountName,
}: MemberBadgeProps) => {
  return (
    <div className={styles.badge}>
      <MemberBadge.Avatar
        accountAvatarUrl={accountAvatarUrl}
        accountName={accountName}
      />
      <MemberBadge.Name name={name} />
      <MemberBadge.Roles roles={roles} />
      <MemberBadge.AccountName accountName={accountName} />
      <MemberBadge.Logo />
    </div>
  );
};

MemberBadge.Avatar = memo(Avatar);
MemberBadge.Name = memo(Name);
MemberBadge.Roles = memo(Roles);
MemberBadge.AccountName = memo(AccountName);
MemberBadge.Logo = memo(Logo);
