import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import MarkdownEditor from '@/components/atoms/MarkdownEditor/MarkdownEditor';
import useBrains from '@/hooks/useBrains';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectBrainId } from '@/redux/session/selectors';
import { capitalizeFirstLetter } from '@/util/util';

import { fieldSchema, MAX_FORM_CHARACTERS } from '../../helper';
import { useTrackFormState } from '../../hooks/useTrackFormState';
import { FormCard } from '../FormCard/FormCard';
import { NumberIcon } from '../NumberIcon/NumberIcon';

type Form = {
  loyalty: string;
};

const FORM_ID = 'ai-agent-loyalty';

export const Loyalty = () => {
  const brainId = useSelector(selectBrainId);
  const { brain, updateBrain } = useBrains(brainId);
  const { t } = useTranslation();

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );

  // RHF
  const {
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<Form>({
    mode: 'onSubmit',
    resolver: yupResolver(
      fieldSchema<Form>('loyalty', MAX_FORM_CHARACTERS, t)
    ) as Resolver<Form>,
    values: {
      loyalty: brain?.guidelines?.loyalty || '',
    },
  });

  const onSubmit = (data: Form) => {
    updateBrain({
      brain_id: brainId,
      guidelines: {
        ...brain?.guidelines,
        loyalty: data.loyalty,
      },
    });
  };

  useTrackFormState({ isDirty, formId: FORM_ID });

  return (
    <FormCard id={FORM_ID} onSubmit={handleSubmit(onSubmit)}>
      <FormCard.Header
        title={t('ai_agents.knowledge.loyalty.title')}
        subtitle={t('ai_agents.knowledge.loyalty.subtitle')}
        icon={
          <NumberIcon
            color="var(--icon-default-blue)"
            size="large"
            number={3}
          />
        }
      />

      <FormCard.Content>
        <MarkdownEditor
          name="loyalty"
          setValue={setValue}
          label={t('ai_agents.navigation.overview')}
          defaultValue={brain?.guidelines?.loyalty}
          placeholder={t('ai_agents.knowledge.features.placeholder')}
          error={!!errors.loyalty}
          errorMessage={capitalizeFirstLetter(errors.loyalty?.message)}
          disabled={!canWrite}
        />
      </FormCard.Content>

      <FormCard.Footer>
        <Button
          disabled={!isDirty}
          type="submit"
          variant="secondary"
          isLoading={isSubmitting}
        >
          {t('common.save')}
        </Button>
      </FormCard.Footer>
    </FormCard>
  );
};
