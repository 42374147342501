import React, { Suspense, StrictMode } from 'react';
import 'regenerator-runtime/runtime';

import '../../../init';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import AsciiArt from '@/components/atoms/AsciiArt';
import Loading from '@/components/atoms/Loading/Loading';
import ApolloProvider from '@/components/templates/ApolloProvider';
import { browserRouter } from '@/components/templates/Routes/RootRouter';
import RtmProvider from '@/components/templates/RtmProvider';
import { getStore } from '@/redux/store';

import theme from '../../../theme';
import './App.css';

const store = getStore();

/**
 * Retry heuristic for react-query. It will try up to 3 times as long as
 * its  a network error.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const retry = (failureCount: number, error: any) =>
  failureCount < 3 &&
  (!error.statusCode || String(error.statusCode).startsWith('5'));

/**
 * Returns the number of ms to wait before retrying a react-query
 */
const retryDelay = (attemptIndex: number) => Math.max(500 * attemptIndex, 2000);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      notifyOnChangeProps: ['data', 'error'],
      staleTime: 60000 /* 1 MINUTE */,
      refetchOnWindowFocus: true,
      retry,
      retryDelay,
    },
    mutations: { retry, retryDelay },
  },
});

if (process.env.NODE_ENV === 'production') {
  // eslint-disable-next-line no-console
  console.log(AsciiArt);
}

const App: React.FC = () => (
  <StrictMode>
    <Suspense fallback={<Loading />}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <ApolloProvider>
            <RtmProvider>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                  <DndProvider backend={HTML5Backend}>
                    <RouterProvider router={browserRouter} />

                    {process.env.NODE_ENV !== 'test' && (
                      <ReactQueryDevtools initialIsOpen={false} />
                    )}
                  </DndProvider>
                </ThemeProvider>
              </StyledEngineProvider>
            </RtmProvider>
          </ApolloProvider>
        </QueryClientProvider>
      </Provider>
    </Suspense>
  </StrictMode>
);

export default App;
