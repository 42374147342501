import { Editor, Transforms, Text, Range, Path, Node } from 'slate';

import { HANDLE_REGEX } from '../../organisms/RichTextEditor/utils';

export type CustomNode = {
  text?: string;
  isMention?: boolean;
};

export const insertMentions = (editor: Editor) => {
  // Collect nodes to process
  const nodesToProcess = Array.from(
    Editor.nodes(editor, {
      at: [],
      match: (n) => Text.isText(n) && !(n as CustomNode).isMention, // Only process unprocessed text nodes
    })
  );

  for (let i = 0; i < nodesToProcess.length; i++) {
    const [node, path] = nodesToProcess[i];
    const { text } = node as CustomNode;
    const matches = [...text.matchAll(HANDLE_REGEX)];

    if (matches.length === 0) continue; // Skip nodes with no matches

    const newNodes = [];
    let lastIndex = 0;

    matches.forEach((match) => {
      const [mention] = match;
      const start = match.index;

      // Text before the mention
      if (start > lastIndex) {
        newNodes.push({
          text: text.slice(lastIndex, start), // Text before the mention
        });
      }

      // Mention node
      newNodes.push({
        text: mention,
        isMention: true, // Mark this node as a mention
      });

      lastIndex = start + mention.length;
    });

    // Remaining text after the last mention
    if (lastIndex < text.length) {
      newNodes.push({
        text: text.slice(lastIndex),
      });
    }

    // Replace the original node with the new nodes
    Transforms.removeNodes(editor, { at: path });
    Transforms.insertNodes(editor, newNodes, { at: path });
    const newPath = [
      ...path.slice(0, -1),
      path[path.length - 1] + newNodes.length - 1,
    ];
    const newOffset = newNodes[newNodes.length - 1]?.text?.length || 0;

    Transforms.select(editor, {
      anchor: { path: newPath, offset: newOffset },
      focus: { path: newPath, offset: newOffset },
    });
  }
};

/**
 * Intercepts the space key to move away from the metnion node and move behavior at the end of mention node.
 */
export const handleKeyDown = (
  event: React.KeyboardEvent<HTMLDivElement>,
  editor: Editor
) => {
  if (event.key === ' ' || event.key === 'Enter') {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      const [node, path] = Editor.node(editor, selection.anchor.path);
      // Check if the current node is a mention and the cursor is at the end
      if (
        (node as CustomNode).isMention &&
        selection.anchor.offset === (node as CustomNode).text.length
      ) {
        if (event.key === ' ') {
          event.preventDefault(); // Prevent default space behavior
        }
        // Path to the next node
        const nextPath = Path.next(path);
        // Check if the next path exists
        if (Editor.hasPath(editor, nextPath)) {
          const nextNode = Node.get(editor, nextPath);
          if (nextNode) {
            // Move cursor to the next node
            Transforms.select(editor, {
              anchor: { path: nextPath, offset: 0 },
              focus: { path: nextPath, offset: 0 },
            });
          }
        } else {
          // If no next node exists, insert a new one and move cursor
          Transforms.insertNodes(editor, { text: ' ' }, { at: nextPath });
          Transforms.select(editor, {
            anchor: { path: nextPath, offset: 1 },
            focus: { path: nextPath, offset: 1 },
          });
        }
      }
    }
  }
};
