import { createSelector } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';

import { Context } from '@/models/chat';
import { RootState } from '@/models/state';
import { BrainMessage, UserMessage } from '@/models/tryIt';
import { AnalyticsType } from '@/modules/analytics/models';

import { groupBy } from '../utils/helper';

export const selectContext = (state: RootState) =>
  state.tryIt.context as Array<Context>;

export const selectFiltersByType = createSelector(
  (state: RootState, type: AnalyticsType) => state.analytics[type],
  (analyticsType) => {
    const newFilters = cloneDeep(analyticsType) as {
      type: 'channels';
      channels: string[];
    }[];

    if (!newFilters || newFilters.length === 0) {
      return [];
    }

    const channels = newFilters?.find((log) => log.type === 'channels');

    if (channels) {
      const newChannels = {
        channels: channels.channels,
        type: 'channels' as const,
      };

      const channelsIndex = newFilters.findIndex(
        (log) => log.type === 'channels'
      );
      newFilters.splice(channelsIndex, 1, newChannels);
    }
    return newFilters;
  }
);

export const selectIsTryItReplay = (state: RootState) => state.tryIt.isReplay;

export const selectIsLastMessage = (state: RootState) =>
  state.tryIt?.selectedMessage?.index + 1 === state.tryIt.context.length - 1;

export const selectCanEdit = createSelector(
  selectIsTryItReplay,
  selectIsLastMessage,
  selectContext,
  (isReplay, isLastMessage, context) =>
    !isReplay && !isLastMessage ? context.length === 1 : true
);

export const selectTryItContext = createSelector(
  selectIsTryItReplay,
  (state: RootState) => state.tryIt?.selectedMessage?.context ?? {},
  (state: RootState) =>
    state.tryIt.context[state.tryIt?.selectedMessage?.index + 1] ??
    state.tryIt.context[state.tryIt.context.length - 1],
  (isReplay, selectedMessageContext, nextContext) =>
    isReplay ? selectedMessageContext : nextContext
);

export const selectContextTags = createSelector(
  selectTryItContext,
  (tryItContext) => tryItContext?.tags
);

const selectCurrentNode = (state: RootState) =>
  state.tryIt.selectedMessage.nodes[state.tryIt.selectedMessage.index];

export const selectNodeName = createSelector(
  selectCurrentNode,
  (currentNode) => currentNode?.name
);

export const selectSelectedMessage = (state: RootState) =>
  state.tryIt.selectedMessage;

export const selectSelectedMessageCollection = createSelector(
  selectCurrentNode,
  (currentNode) => currentNode?.collection
);

export const selectSelectedMessageFragments = createSelector(
  selectSelectedMessageCollection,
  (collection) => collection?.fragments
);

export const selectSelectedMessageIndex = (state: RootState) =>
  state.tryIt.selectedMessage.index;

export const selectTryItSessionId = (state: RootState) => state.tryIt.sessionId;

export const selectSelectedMessageNodesStack = createSelector(
  selectSelectedMessage,
  selectSelectedMessageIndex,
  (selectedMessage, index) => selectedMessage.nodes?.[index]?.nodes_stack
);

export const selectNodesStackParentNodeNameByIndex = (index: number) =>
  createSelector(selectSelectedMessage, (selectedMessage) => {
    if (isNil(selectedMessage.nodes[index]?.name)) {
      return selectedMessage.nodes[index]?.name;
    }

    const nodesStack = selectedMessage.nodes[index]?.nodes_stack;

    // For backwards compatibility, if the request_id is not present
    if (!nodesStack?.[0]?.request_id) {
      return selectedMessage.nodes[index]?.name;
    }

    const groupedNodesStack = groupBy(nodesStack, 'request_id');

    return nodesStack
      ? groupedNodesStack[groupedNodesStack?.length - 1]?.[0].name
      : null;
  });

export const selectSelectedMessageCollectionId = createSelector(
  selectSelectedMessageCollection,
  (collection) => collection?.collection_id
);

export const selectStandaloneQuestion = createSelector(
  selectCurrentNode,
  (currentNode) => currentNode?.standalone_question
);

export const selectIsStandaloneAltered = createSelector(
  selectCurrentNode,
  selectStandaloneQuestion,
  (currentNode, standalone) =>
    standalone && standalone !== (currentNode?.messages[0] as UserMessage)?.text
);

export const selectThumbsUp = createSelector(
  (state: RootState) => state.tryIt.selectedMessage.nodes,
  (nodes) => {
    const thumbsUp = nodes
      .map((item, index) => {
        const firstMessage = (
          item.messages[item.messages.length - 1] as BrainMessage
        )?.responses?.[0];
        const isURLType = firstMessage?.type === 'url';
        const textKey = isURLType ? 'url' : 'text';

        return {
          request_id: item.request_id,
          reaction: item.reaction,
          firstMessageText: (
            item.messages[item.messages.length - 1] as BrainMessage
          )?.responses?.[0]?.[textKey],
          nodeIndex: index,
        };
      })
      .filter((item) => item.reaction === '1');

    return thumbsUp || [];
  }
);

export const selectThumbsDown = createSelector(
  (state: RootState) => state.tryIt.selectedMessage.nodes,
  (nodes) => {
    const thumbsDown = nodes

      .map((item, index) => {
        const firstMessage = (
          item.messages[item.messages.length - 1] as BrainMessage
        )?.responses?.[0];
        const isURLType = firstMessage?.type === 'url';
        const textKey = isURLType ? 'url' : 'text';

        return {
          request_id: item.request_id,
          reaction: item.reaction,
          firstMessageText: (
            item.messages[item.messages.length - 1] as BrainMessage
          )?.responses?.[0]?.[textKey],
          nodeIndex: index,
        };
      })
      .filter((item) => item.reaction === '-1');

    return thumbsDown || [];
  }
);
