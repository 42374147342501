import { createSelector } from '@reduxjs/toolkit';

export const selectAnyFormDirty = createSelector(
  (state) => state.aiAgents,
  (forms) => Object.values(forms).some((isDirty) => isDirty)
);

export const selectDirtyForms = createSelector(
  (state) => state.aiAgents,
  (forms) =>
    Object.entries(forms)
      .filter(([, isDirty]) => isDirty)
      .map(([formId]) => formId)
);
