import { t, TFunction } from 'i18next';
import * as yup from 'yup';

import {
  BrainGuidelines,
  BrainLanguage,
  CustomGuideline,
} from '@/models/brain';

import { BrainTypeValues, ResponseLength } from './models';

export const MAX_FORM_CHARACTERS = 10000;

export const getAIAgentType = (brain_type: BrainTypeValues) => {
  switch (brain_type) {
    case 'activation':
      return t('ai_agents.types.activation.title');
    case 'support':
      return t('ai_agents.types.support.title');
    case 'debt_collection':
      return t('ai_agents.types.debt_collection.title');
    case 'engagement':
      return t('ai_agents.types.engagement.title');
    case 'upsell':
      return t('ai_agents.types.upsell.title');
    case 'sales_fintech':
      return t('ai_agents.types.sales_fintech.title');
    case 'sales_ecommerce':
      return t('ai_agents.types.sales_ecommerce.title');
    default:
      return brain_type;
  }
};

/**
 * Converts a given flag emoji string into its corresponding country code.
 *
 * This function takes an emoji flag, converts its Unicode code points to the corresponding
 * country code, then converts it to lowercase.
 *
 * @param {string} flag - The flag emoji to be converted into a country code.
 * @returns {string} The two-letter country code in lowercase.
 *
 * @example
 * Returns 'gr' for the Greek flag emoji 🇬🇷
 * emojiFlagToCountryCode('🇬🇷');
 */
export const emojiFlagToCountryCode = (flag: string): string => {
  return Array.from(flag, (codeUnit) => codeUnit.codePointAt(0) ?? 0)
    .map((char) => String.fromCharCode(char - 127397).toLowerCase())
    .join('');
};

export const getResponseLength = (length: ResponseLength) => {
  switch (length) {
    case 'short':
      return t('ai_agents.response_length.short');
    case 'medium':
      return t('ai_agents.response_length.medium');
    case 'long':
      return t('ai_agents.response_length.long');
  }
};

// This is a mapping of language codes to the maximum number of characters allowed in that language.
export const getMaximumCharactersByLanguage = (language: BrainLanguage) => {
  switch (language) {
    case 'en':
    default:
      return 30000;
  }
};

// Calculates the current length of characters in the guidelines
export const sumCurrentCharacters = (guidelines: BrainGuidelines) => {
  const overviewSum = guidelines?.overview?.length || 0;
  const featuresSum = guidelines?.features?.length || 0;
  const loyaltySum = guidelines?.loyalty?.length || 0;
  const customGuidelinesSum =
    guidelines?.custom_guidelines?.reduce(
      (total: number, item: CustomGuideline) =>
        total + (item.body ? item.body.length : 0),
      0
    ) || 0;
  const objectionsSum =
    guidelines?.objections?.reduce((total, objection) => {
      return total + objection.message.length + objection.response.length;
    }, 0) || 0;

  const customIntegrationsSum = guidelines?.custom_instructions?.length || 0;

  return (
    overviewSum +
    featuresSum +
    loyaltySum +
    customGuidelinesSum +
    objectionsSum +
    customIntegrationsSum
  );
};

export const fieldSchema = <T extends Record<string, string>>(
  name: keyof T,
  limit: number,
  t: TFunction
): yup.ObjectSchema<T> => {
  return yup.object().shape({
    [name]: yup.string().max(limit, t('validation.max_length', { max: limit })),
  }) as yup.ObjectSchema<T>;
};
