import { Icon } from '@/models/icon';

type PinProps = {
  marked?: boolean;
} & Icon;

export const Pin = ({ size = 16, color, marked = false }: PinProps) => {
  if (marked) {
    return (
      <svg
        width={size}
        height={size}
        fill={color ?? 'var(--icon-default-blue)'}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-1 -1 15 15"
      >
        <path
          d="M12.7 5.5 9.38 8.85c.28.8.4 2.12-.83 3.75a1 1 0 0 1-.73.4h-.07a1 1 0 0 1-.7-.3L4.01 9.7l-2.67 2.66a.5.5 0 0 1-.7-.7L3.3 8.98.3 5.96a1 1 0 0 1 .09-1.48 3.99 3.99 0 0 1 3.77-.82L7.5.29a1 1 0 0 1 1.41 0l3.8 3.8a1 1 0 0 1 0 1.41Z"
          fill={color ?? 'var(--icon-default-blue)'}
        />
      </svg>
    );
  } else {
    return (
      <svg
        width={size}
        height={size}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-1 -1 15 15"
      >
        <path
          d="M12.7 4.09 8.92.29A1 1 0 0 0 7.5.3L4.15 3.66a3.99 3.99 0 0 0-3.78.82A1 1 0 0 0 .3 5.96l3.02 3.02-2.66 2.67a.5.5 0 0 0 .7.7L4.02 9.7l3.02 3.02a1 1 0 0 0 .7.29h.07a1 1 0 0 0 .73-.4c1.23-1.63 1.11-2.95.83-3.75L12.7 5.5a1 1 0 0 0 0-1.41Zm-.7.7L8.42 8.4a.5.5 0 0 0-.1.57c.6 1.18-.1 2.41-.58 3.04L1 5.26a3.2 3.2 0 0 1 2.03-.77c.35 0 .7.06 1.02.2a.5.5 0 0 0 .58-.1L8.2 1 12 4.8Z"
          fill={color ?? 'var(--icon-default-gray)'}
        />
      </svg>
    );
  }
};
