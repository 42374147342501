import { ElementType, createElement } from 'react';

const timeUnitMapping: Record<string, string> = {
  second: 's',
  seconds: 's',
  minute: 'm',
  minutes: 'm',
  hour: 'hr',
  hours: 'hr',
  day: 'd',
  days: 'd',
  month: 'mo',
  months: 'mo',
  year: 'y',
  years: 'y',
};

// this is doing what moment.updateLocale does, but without mutating the global moment locale
export const timeFormat = (str: string): string => {
  const timeArray = str.split(' ');

  if (timeArray[0] === 'a' && timeArray[1] === 'few') {
    return 'now';
  }
  if (timeArray[0] === 'a' || timeArray[0] === 'an') {
    timeArray[0] = '1';
  }

  const mapped = timeUnitMapping[timeArray[1]];
  if (mapped) {
    timeArray[1] = mapped;
  }

  return timeArray.slice(0, 2).join('') + ' ' + timeArray.slice(2).join(' ');
};

export const IconComponent = ({
  icon,
}: {
  icon: ElementType | JSX.Element;
}) => {
  if (typeof icon === 'object') {
    return icon;
  } else if (typeof icon === 'function') {
    return createElement(icon);
  }
  return null;
};

export const trimText = (text: string, length: number) => {
  if (text?.length > length) {
    return `${text.substring(0, length)}...`;
  }
  return text;
};
