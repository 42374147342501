import { useState, useEffect, useCallback } from 'react';

import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography/Typography';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useLocation } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import { useTitle } from 'react-use';

import { BackgroundWrapper } from '@/components/atoms/BackgroundWrapper/BackgroundWrapper';
import Button from '@/components/atoms/Button/Button/Button';
import HeadManager from '@/components/atoms/HeadManager/HeadManager';
import IconGoogle from '@/components/atoms/Icons/Google';
import Input from '@/components/atoms/Input/Input';
import Loading from '@/components/atoms/Loading/Loading';
import { Divider } from '@/components/organisms/Divider/Divider';
import useUser from '@/hooks/useUser';
import { PageName, EventName } from '@/models/segment';
import { loadUser } from '@/redux/user/actions';
import {
  selectIsAuthenticated,
  selectIsLoadingLogout,
  selectIsLoadingUser,
  selectIsTempUser,
  selectUserInvitation,
} from '@/redux/user/selectors';
import { pageView, trackEvent } from '@/segment/segment';
import { emailRules } from '@/util/validator';

import styles from '../Signup.module.scss';

type LoginParams = {
  email: string;
};

const PROVIDERS = window.AUTH_PROVIDERS || 'email,google';

const HAS_GOOGLE = PROVIDERS.includes('google');
const HAS_EMAIL = PROVIDERS.includes('email');

const Login: React.FC = () => {
  const { t } = useTranslation();
  useTitle(t('pages.login'));

  const dispatch = useDispatch();
  const location = useLocation();
  const isLoading = useSelector(selectIsLoadingUser);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isTempUser = useSelector(selectIsTempUser);
  const isLoggingOut = useSelector(selectIsLoadingLogout);
  const hasInvitation = useSelector(selectUserInvitation);
  const [searchParams] = useSearchParams();

  const [loadingUser, setLoadingUser] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setLoadingUser(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (!isAuthenticated && !isTempUser && !isLoggingOut) {
      pageView(PageName.LOGIN);
      dispatch(loadUser());
    }
  }, [dispatch, isAuthenticated, isLoggingOut, isTempUser]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<LoginParams>();

  const { loginWithMagicLink } = useUser();

  const redirectTo = String(location.state || '/');
  const encodedRedirect = encodeURIComponent(redirectTo);
  let errorMessage = searchParams.get('error_message');
  if (errorMessage === 'invalid-email') {
    errorMessage = 'errors.company_email';
  }
  const qs = redirectTo && redirectTo !== '/' ? `?to=${encodedRedirect}` : '';

  const handleLoginWithMagicLink = useCallback(
    async ({ email }) => {
      setIsSubmitting(true);
      try {
        trackEvent(EventName.ClickLoginWithEmail);
        await loginWithMagicLink({ email, redirectTo });
      } catch (error) {
        setError(
          'email',
          { type: 'custom', message: error.rawMessage || error.message },
          { shouldFocus: true }
        );
      }
      setIsSubmitting(false);
    },
    [loginWithMagicLink, redirectTo, setError]
  );

  if (hasInvitation) {
    return <Navigate to="/invitation/details" />;
  }

  // Save the redirectTo from the original request
  // in case the redirect is to an specific onboarding
  if (isTempUser) {
    return <Navigate to={`/signup/welcome${qs}`} />;
  }

  if (isAuthenticated) {
    return <Navigate to={redirectTo} />;
  }

  if (loadingUser) {
    return <Loading />;
  }

  return (
    <BackgroundWrapper
      title={t('login.log_in')}
      subtitle={
        <>
          {t('login.no_account')}{' '}
          <Link to={`/signup${qs}`}>
            <Typography className={styles.link} variant="subheading-semi-bold">
              {t('login.sign_up_here')}
            </Typography>
          </Link>
        </>
      }
      showBranding
    >
      <HeadManager
        title="Login to Moveo.AI and experience the future of Customer Service"
        description="Login to Moveo.AI and start creating your GenAI agent. Automate over 70% of your processes, personalize customer interactions and boost employee efficiency."
      >
        {errorMessage && (
          <Alert className={styles.alert} severity="error">
            {t(errorMessage)}
          </Alert>
        )}
        {HAS_EMAIL && (
          <form onSubmit={handleSubmit(handleLoginWithMagicLink)}>
            <Input
              size="xlarge"
              name="email"
              id="email"
              label="email"
              placeholder="Email"
              errorMessage={errors.email?.message}
              autoComplete="email"
              {...register('email', emailRules(t))}
            />
            <span className={styles.buttonWrapper}>
              <Button
                type="submit"
                size="large"
                isFullWidth
                className={styles.button}
                isLoading={isSubmitting}
              >
                {t('login.log_in')}
              </Button>
            </span>
          </form>
        )}
        {HAS_EMAIL && HAS_GOOGLE && (
          <Divider text={t('rules.or').toUpperCase()} />
        )}
        {HAS_GOOGLE && (
          <Button
            href={`/www/api/auth/google?to=${encodedRedirect}`}
            size="large"
            isFullWidth
            isLink
            variant="social"
            className={styles.social}
            onClick={() => trackEvent(EventName.ClickLoginWithGoogle)}
          >
            <IconGoogle size={24} />
            {t('login.continue_with', { 0: t('login.google') })}
          </Button>
        )}
      </HeadManager>
    </BackgroundWrapper>
  );
};

export default Login;
