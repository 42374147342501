import { useEffect } from 'react';

import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTitle } from 'react-use';

import EntityImage from '@/components/atoms/Images/EntityImage';
import { PageName } from '@/models/segment';
import { selectAccountSlug } from '@/redux/session/selectors';
import { pageView } from '@/segment/segment';

import styles from './Conversations.module.scss';

export default function Conversations() {
  const { t } = useTranslation();
  const slug = useSelector(selectAccountSlug);

  useEffect(() => {
    pageView(PageName.CONVERSATIONS);
  }, []);

  useTitle(t('pages.conversations', { 0: slug }));
  return (
    <div className={styles.wrapper}>
      <EntityImage />
      <Typography variant="h1-bold" component="h4">
        {t('conversation.select_a_conversation')}
      </Typography>
    </div>
  );
}
