import Badge, { BadgeProps } from '@mui/material/Badge';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import styled from '@mui/system/styled';

type ExtendedListItemProps = ListItemProps & { customDisabled: boolean };

export const Item = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'customDisabled',
})<ExtendedListItemProps>(({ customDisabled }) => ({
  '&': {
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 'auto',
    height: 'var(--space-36)',
    color: 'var(--text-default-gray)',

    '&:hover': {
      backgroundColor: customDisabled
        ? 'inherit'
        : 'var(--surface-secondary-gray-light)',
      background: customDisabled
        ? 'inherit'
        : 'var(--surface-secondary-gray-light)',
      '.MuiListItemSecondaryAction-root > div > button': {
        opacity: '1',
      },
    },
    '&.Mui-selected': {
      backgroundColor: 'var(--surface-secondary-gray-light)',
      background: 'var(--surface-secondary-gray-light)',
    },
    ...(customDisabled ? { cursor: 'not-allowed' } : {}),
  },
  '& .MuiListItemButton-root': {
    padding: 'var(--space-8) var(--space-12) var(--space-8) var(--space-16)',
    width: 'auto',
    height: 'var(--space-36)',
    '&:hover': {
      backgroundColor: 'transparent',
      background: 'transparent',
    },
  },
  '& .MuiListItemText-root': {
    '& .MuiTypography-root': {
      fontWeight: 'inherit',
      color: 'inherit',
    },
  },
  '& .MuiListItemIcon-root': {
    minWidth: 'auto',
    marginRight: 'var(--space-8)',
    color: 'inherit',
  },
  '& .MuiListItemSecondaryAction-root': {
    padding: 'var(--space-8) var(--space-12) var(--space-8) var(--space-16)',
    right: 'var(--space-4)',
    '& > div > button': {
      opacity: '0',
      padding: '0',
      width: 'var(--space-24)',
      height: 'var(--space-24)',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      borderRadius: 'var(--space-4)',
      '&:hover': {
        backgroundColor: 'var(--surface-tertiary-blue-light)',
      },
    },
  },
}));

export const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    backgroundColor: 'var(--icon-default-error)',
    color: 'var(--text-default-error)',
    right: '35%',
    height: 'var(--space-4)',
    minWidth: 'var(--space-4)',
  },
}));
