import { useState } from 'react';

import { cn, withRef } from '@udecode/cn';
import { getMentionOnSelectItem } from '@udecode/plate-mention';

import {
  InlineCombobox,
  InlineComboboxContent,
  InlineComboboxEmpty,
  InlineComboboxGroup,
  InlineComboboxInput,
  InlineComboboxItem,
} from './inline-combobox';
import { PlateElement } from './plate-element';

const onSelectItem = getMentionOnSelectItem();

export const MENTIONABLES = [
  { key: '0', text: 'aya', label: 'Aya' },
  { key: '1', text: 'aaaa', label: 'Aaaa' },
  {
    key: '2',
    text: 'admiral',
    label: 'Admiral',
  },
];

export const MentionInputElement = withRef<typeof PlateElement>(
  ({ className, ...props }, ref) => {
    const { children, editor, element } = props;
    const [search, setSearch] = useState('');

    return (
      <PlateElement
        ref={ref}
        as="span"
        data-slate-value={element.value}
        {...props}
      >
        <InlineCombobox
          value={search}
          element={element}
          setValue={setSearch}
          showTrigger={false}
          trigger="$"
        >
          <span
            className={cn(
              'inline-block rounded-md bg-muted px-1.5 py-0.5 align-baseline text-sm ring-ring focus-within:ring-2',
              className
            )}
          >
            <InlineComboboxInput />
          </span>

          <InlineComboboxContent className="my-1.5">
            <InlineComboboxEmpty>No results</InlineComboboxEmpty>

            <InlineComboboxGroup>
              {MENTIONABLES.map((item) => (
                <InlineComboboxItem
                  key={item.key}
                  value={item.text}
                  onClick={() => onSelectItem(editor, item, search)}
                >
                  {item.label}
                </InlineComboboxItem>
              ))}
            </InlineComboboxGroup>
          </InlineComboboxContent>
        </InlineCombobox>

        {children}
      </PlateElement>
    );
  }
);
