import { memo } from 'react';

import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useTranslation } from 'react-i18next';

import { Avatar } from '@/components/atoms/Avatar/Avatar/Avatar';
import Button from '@/components/atoms/Button/Button/Button';
import CopyButton from '@/components/atoms/CopyButton/CopyButton';
import Environment from '@/components/atoms/Icons/Environment';
import Play from '@/components/atoms/Icons/Play';
import Robot from '@/components/atoms/Icons/Robot';
import SingleRatingStar from '@/components/atoms/Icons/SingleRatingStar';
import StatusBadge from '@/components/atoms/StatusBadge/StatusBadge';
import useBrains from '@/hooks/useBrains';
import useDesks from '@/hooks/useDesks';
import useMembers from '@/hooks/useMembers';
import { Log } from '@/modules/analytics/models';
import { formatLogs } from '@/util/analytics';
import { durationFormat } from '@/util/util';

import { ThumbsCounter } from './ThumbsCounter';

interface SessionFieldProps {
  session: Log;
  style: React.CSSProperties;
  onReplayClick: ({ session, index }: { session: Log; index: number }) => void;
  index: number;
  lastSeen: boolean;
  recentlySeen: boolean;
  isBrainLogs: boolean;
}

const SessionField = ({
  session,
  style,
  onReplayClick,
  index,
  recentlySeen,
  isBrainLogs,
}: SessionFieldProps) => {
  const {
    user_name,
    user_id,
    start_time,
    end_time,
    total_user_messages,
    tags,
    thumbs_down,
    thumbs_up,
    desk_id,
    channel,
    is_contained,
    is_covered,
    is_test,
    session_id,
    rating,
    participated_agents,
    participated_brains,
  } = session;

  const { t } = useTranslation();
  const { brains } = useBrains();
  const { desk } = useDesks(desk_id);
  const { members } = useMembers();

  const handleClick = () => {
    if (onReplayClick) {
      onReplayClick({ session, index });
    }
  };

  const { Icon, formattedTime, duration } = formatLogs({
    channel,
    start_time,
    end_time,
  });

  return (
    <div className="logs-field " key={user_id} style={style}>
      <div className="logs-field__user">
        <Tooltip arrow title={user_name ? user_name : ''}>
          <p className="logs__info">
            {user_name ?? (
              <span className="logs__info--unavailable">
                {t('common.anonymous')}
              </span>
            )}
          </p>
        </Tooltip>
        {Icon && (
          <p className="logs__subinfo">
            <Icon size={16} />
            {typeof channel === 'string' && t(`channels.${channel}`)}
            {channel === 'web' && is_test && <> (in preview)</>}
          </p>
        )}
        <CopyButton
          data={user_id}
          className="logs__subinfo__copybutton"
          iconSize={14}
        >
          <Tooltip arrow title={user_id}>
            <span className="logs__subinfo">{t('analytics.copy_id')}</span>
          </Tooltip>
        </CopyButton>
      </div>
      <div className="logs-field__session">
        <p className="logs__info">{formattedTime}</p>
        <p className="logs__subinfo">
          {total_user_messages} {t('common.message')}
          {total_user_messages !== 1 && 's'} ·{' '}
          {durationFormat(t, duration, t('common.na'))}
        </p>
        <CopyButton
          data={session_id}
          className="logs__subinfo__copybutton"
          iconSize={14}
        >
          <Tooltip arrow title={session_id}>
            <p className="logs__subinfo">{t('analytics.copy_id')}</p>
          </Tooltip>
        </CopyButton>
      </div>
      {!isBrainLogs && (
        <div className="logs-field__desk">
          <Tooltip arrow title={desk?.name}>
            <span className="logs__info__env">
              {desk?.name ? (
                <>
                  <Environment /> <p>{desk?.name}</p>
                </>
              ) : (
                <p>{t('common.na')}</p>
              )}
            </span>
          </Tooltip>
        </div>
      )}
      <div className="logs-field__attributes">
        <div className="logs-field__attributes__row">
          <StatusBadge
            tooltip={t('analytics.covered_help')}
            label={
              is_covered
                ? t('analytics.charts_tooltip.cover')
                : t('analytics.charts_tooltip.not_cover')
            }
            variant={is_covered ? 'success' : 'warning'}
            withIcon
          />
        </div>
        <div className="logs-field__attributes__row">
          <StatusBadge
            tooltip={t('analytics.contained_help')}
            label={
              is_contained
                ? t('analytics.charts_tooltip.contain')
                : t('analytics.charts_tooltip.not_contain')
            }
            variant={is_contained ? 'success' : 'warning'}
            withIcon
          />
        </div>
      </div>

      {!isBrainLogs && (
        <div className="logs-field__agents-brains">
          {participated_brains?.length > 0 && (
            <div className="logs-field__agents-brains__row">
              <Tooltip
                arrow
                title={
                  <>
                    {participated_brains.map((brain) => (
                      <Typography
                        component="p"
                        key={`${brain.brain_id}-${brain.brain_parent_id}`}
                        className="logs-field__agents-brains__row__brains__tooltip"
                        variant="label-regular"
                      >
                        {
                          brains?.find(
                            (b) => b.brain_id === brain.brain_parent_id
                          )?.name
                        }
                      </Typography>
                    ))}
                  </>
                }
              >
                <div className="logs-field__agents-brains__row__brains">
                  <Robot size={20} />
                  {participated_brains?.length > 1 && (
                    <StatusBadge
                      label={`${participated_brains?.length}`}
                      withIcon={false}
                      variant="info"
                    />
                  )}
                </div>
              </Tooltip>
            </div>
          )}
          {participated_agents?.length > 0 && (
            <div className="logs-field__agents-brains__row">
              {participated_agents.map((agent) => (
                <Avatar
                  size="small"
                  key={agent.agent_id}
                  userId={
                    members?.find((mem) => mem.user_id === agent.agent_id)
                      ?.user_id
                  }
                />
              ))}
            </div>
          )}
        </div>
      )}
      <div className={cn('logs-field__rating', { human_logs: !isBrainLogs })}>
        {rating ? (
          <div className="logs-field__rating__row">
            <p className="logs__info">{rating}</p>
            <SingleRatingStar />
          </div>
        ) : (
          <p className="logs__info">{t('common.na')}</p>
        )}

        <ThumbsCounter thumbsUp={thumbs_up} thumbsDown={thumbs_down} />
      </div>

      <div className={cn('logs-field__tags', { human_logs: !isBrainLogs })}>
        <Scrollbars autoHide>
          {tags?.map((tag: string) => (
            <Chip key={tag} size="small" label={tag} color="primary" />
          ))}
        </Scrollbars>
      </div>

      <Button
        className="logs-field__replay"
        onClick={handleClick}
        variant={recentlySeen ? 'secondary' : 'primary'}
      >
        <Play
          size={16}
          color={
            recentlySeen
              ? 'var(--icon-default-blue)'
              : 'var(--icon-default-white)'
          }
        />
      </Button>
    </div>
  );
};

export default memo(SessionField);
