import { useCallback, useMemo, useState } from 'react';

import isNil from 'lodash/isNil';
import { useSelector } from 'react-redux';

import { isConditionNested } from '@/components/organisms/Condition/util';
import useBrains from '@/hooks/useBrains';
import useCollections from '@/hooks/useCollections';
import { useIntegrations } from '@/hooks/useIntegrations';
import { Brain } from '@/models/brain';
import { useRules } from '@/modules/rules/hooks/useRules';
import { ActionType } from '@/modules/rules/model';
import { selectDeskId } from '@/redux/session/selectors';

interface RuleWithBrain {
  brain: Brain;
  brain_version: number;
  rule_id: string;
  integrationTypes: string[];
  collection_name?: string;
  collection_id?: string;
}
[];
const useHomeOverview = () => {
  const deskId = useSelector(selectDeskId);
  const { rules, listStatus: listRules } = useRules(deskId);
  const { brains, listStatus: listBrains } = useBrains();
  const { collections } = useCollections();
  const [active, setActive] = useState(null);

  const { integrations } = useIntegrations(deskId);

  const filteredIntegrations = integrations?.sort(
    (a, b) =>
      new Date(b.last_session_at).getTime() -
      new Date(a.last_session_at).getTime()
  );

  const rulesWithBrains: RuleWithBrain[] = useMemo(
    () =>
      rules?.rules?.reduce((acc, rule) => {
        if (rule.status === 'active') {
          const action = rule?.actions?.find(
            (action) => action.type === ActionType.ASSIGN_BRAIN
          );
          const integrationTypes = [];
          if (!isConditionNested(rule?.condition)) {
            rule?.condition?.conditions.find((c) => {
              if (c?.attribute?.includes('channel')) {
                integrationTypes.push(c?.attribute?.split('.')[1]);
              }
            });
          } else {
            rule?.condition?.conditions.map((c) =>
              c?.conditions?.find((nestedCond) => {
                if (nestedCond.attribute.includes('channel')) {
                  integrationTypes.push(nestedCond?.attribute?.split('.')[1]);
                }
              })
            );
          }
          if (action) {
            const brain = brains?.find(
              (b) => b.brain_id === action.brain_parent_id
            );

            const collection = collections?.find(
              (c) => c.collection_id === brain?.collection_id
            );
            acc.push({
              brain,
              brain_version: action.brain_version,
              rule_id: rule.rule_id,
              integrationTypes,
              collection_name: collection?.name,
              collection_id: collection?.collection_id,
            });
          }
        }
        return acc;
      }, [] as RuleWithBrain[]),
    [brains, collections, rules?.rules]
  );

  const isActiveIntegration =
    rulesWithBrains?.[active]?.integrationTypes.length > 0;

  const shouldHighlightIntegration = useCallback(
    (index: number) => {
      if (isActiveIntegration) {
        const foundIntegration = rulesWithBrains?.[
          active
        ]?.integrationTypes.find((i) => i === integrations[index].type);
        return foundIntegration;
      }
      return !isNil(active);
    },
    [isActiveIntegration, active, rulesWithBrains, integrations]
  );

  return {
    rulesWithBrains,
    integrations: filteredIntegrations,
    active,
    setActive,
    shouldHighlightIntegration,
    isLoading: listRules === 'pending' || listBrains === 'pending',
  };
};

export default useHomeOverview;
