import addDeepdash from 'deepdash';
import isEqual from 'lodash/isEqual';
import lodash from 'lodash/lodash';

import { RootState } from '@/models/state';
import { ActionType } from '@/modules/rules/model';
import { keysToOmit } from '@/redux/condition/selectors';

import { Condition } from '../../../../ts/models/condition';

// Compare nested objects
const _ = addDeepdash(lodash);

export const selectRule = (state: RootState) => state.rule;

export const selectRuleTriggers = (state: RootState) => state.rule.triggers;

export const selectHasRuleTriggers = (state: RootState) =>
  state.rule.triggers.length > 0;

export const selectTriggerIsOpen = (state: RootState, index) =>
  state.rule.triggers[index]?.isOpen;

export const selectRuleActions = (state: RootState) => state.rule.actions;

export const selectHasRuleActions = (state: RootState) => {
  return state.rule.actions.length > 0;
};

export const selectTriggerOptions = (state: RootState) => state.rule.options;

const isError = (arr) => {
  const result = [];
  arr?.forEach((action) => result.push(action.isError));
  return result.some((x) => x === true);
};

export const selectIsActionError = (state: RootState) => {
  return isError(state.rule.actions);
};

export const selectIsTriggerError = (state: RootState) => {
  return isError(state.rule.triggers);
};

export const selectActionIsOpen = (state: RootState, index) =>
  state.rule.actions[index]?.isOpen;

export const selectIsRuleDirty = (state: RootState) => {
  const omitDeepSafe = (obj: Condition) => {
    if (!obj || typeof obj !== 'object') {
      return obj;
    }

    // Ensure empty arrays are untouched
    if (Array.isArray(obj)) {
      return obj.map(omitDeepSafe);
    }

    return _.omitDeep(obj, keysToOmit);
  };

  return (
    !isEqual(
      omitDeepSafe({ ...state.rule.condition }),
      omitDeepSafe({ ...state.condition.condition })
    ) || state.rule.dirty
  );
};

export const selectActionsOptions = (state: RootState) =>
  state.rule.actionsOptions;

export const selectIsAssignBrainSelected = (state: RootState) =>
  state.rule.actions.some((action) => action.type === ActionType.ASSIGN_BRAIN);
