import { Socket as SocketClient } from 'socket.io-client';

import { Statuses } from './api';
import { Brain, BrainVersion } from './brain';
import { BusinessHours } from './businessHours';
import {
  Conversation,
  Message,
  MarkMessages,
  SessionActivity,
  Typing,
} from './chat';
import { Collection, Datasource } from './collections';
import { Desk } from './desk';
import { DeskWebhook } from './deskWebhooks';
import { Dialog } from './dialog';
import { Entity } from './entity';
import { Integration } from './integration';
import { Intent } from './intent';
import { Macro } from './macros';
import { Invitation } from './member';
import { Presence } from './presence';
import { Recommendation } from './recommendations';
import { ServiceLevelAgreement } from './sla';
import { Variable } from './variables';
import { Webhook } from './webhook';
import { Broadcast } from '../../client/modules/broadcast/models';
import { Bundle } from '../../client/modules/bundles/models';
import { Rule } from '../../client/modules/rules/model';

export enum SocketEvent {
  subscribe_account = 'subscribe:account',
  subscribe_brain = 'subscribe:brain',
  subscribe_broadcast = 'subscribe:broadcast',
  subscribe_desk = 'subscribe:desk',
  subscribe_collection = 'subscribe:collection',
  subscribe_datasource = 'subscribe:datasource',
  subscribe_conversation = 'subscribe:conversation',

  unsubscribe_account = 'unsubscribe:account',
  unsubscribe_brain = 'unsubscribe:brain',
  unsubscribe_broadcast = 'unsubscribe:broadcast',
  unsubscribe_desk = 'unsubscribe:desk',
  unsubscribe_collection = 'unsubscribe:collection',
  unsubscribe_datasource = 'unsubscribe:datasource',
  unsubscribe_conversation = 'unsubscribe:conversation',

  agent_activity = 'agent:activity',
  agent_online = 'agent:online',
  agent_offline = 'agent:offline',
  agent_presence = 'agent:presence',

  conversations_created = 'conversations:created',
  conversations_expired = 'conversations:expired',
  conversations_updated = 'conversations:updated',
  conversations_removed = 'conversations:removed',
  conversations_reopened = 'conversations:reopened',
  conversations_resolved = 'conversations:resolved',
  conversations_handover = 'conversations:handover',
  conversations_assigned = 'conversations:assigned',
  conversations_activity = 'conversations:activity',
  conversations_viewers = 'conversations:viewers',
  conversations_viewing = 'conversations:viewing',
  conversations_viewing_stop = 'conversations:viewing:stop',

  messages_created = 'messages:created',
  messages_compose = 'messages:compose',
  messages_read = 'messages:read',
  messages_delivered = 'messages:delivered',

  invitations_created = 'invitations:created',
  invitations_updated = 'invitations:updated',
  invitations_removed = 'invitations:removed',
  invitations_accepted = 'invitations:accepted',
  invitations_rejected = 'invitations:rejected',

  // Brain changes
  webhooks_created = 'webhooks:created',
  webhooks_updated = 'webhooks:updated',
  webhooks_removed = 'webhooks:removed',

  intents_created = 'intents:created',
  intents_updated = 'intents:updated',
  intents_removed = 'intents:removed',
  intents_renamed = 'intents:renamed',
  intents_collection_updated = 'intents:collection_updated',

  entities_created = 'entities:created',
  entities_updated = 'entities:updated',
  entities_removed = 'entities:removed',
  entities_renamed = 'entities:renamed',
  entities_collection_updated = 'entities:collection_updated',

  dialogs_created = 'dialogs:created',
  dialogs_updated = 'dialogs:updated',
  dialogs_removed = 'dialogs:removed',
  dialogs_collection_updated = 'dialogs:collection_updated',

  variables_created = 'variables:created',
  variables_updated = 'variables:updated',
  variables_removed = 'variables:removed',

  brains_created = 'brains:created',
  brains_updated = 'brains:updated',
  brains_removed = 'brains:removed',
  brains_status = 'brains:status',
  brains_viewers = 'brains:viewers',
  brains_viewing = 'brains:viewing',
  brains_viewing_stop = 'brains:viewing:stop',

  versions_created = 'versions:created',
  versions_removed = 'versions:removed',

  recommendations_configured = 'recommendations:configured',

  desks_created = 'desks:created',
  desks_updated = 'desks:updated',
  desks_removed = 'desks:removed',

  rules_created = 'rules:created',
  rules_updated = 'rules:updated',
  rules_removed = 'rules:removed',
  rules_reordered = 'rules:reordered',

  bundles_created = 'bundles:created',
  bundles_updated = 'bundles:updated',
  bundles_removed = 'bundles:removed',

  business_hours_created = 'business_hours:created',
  business_hours_updated = 'business_hours:updated',
  business_hours_removed = 'business_hours:removed',

  desk_webhooks_created = 'desk_webhooks:created',
  desk_webhooks_updated = 'desk_webhooks:updated',
  desk_webhooks_removed = 'desk_webhooks:removed',

  integrations_created = 'integrations:created',
  integrations_updated = 'integrations:updated',
  integrations_removed = 'integrations:removed',

  slas_created = 'slas:created',
  slas_updated = 'slas:updated',
  slas_removed = 'slas:removed',

  macros_created = 'macros:created',
  macros_updated = 'macros:updated',
  macros_removed = 'macros:removed',

  // Broadcast changes
  broadcasts_created = 'broadcasts:created',
  broadcasts_updated = 'broadcasts:updated',
  broadcasts_removed = 'broadcasts:removed',
  broadcasts_viewers = 'broadcasts:viewers',
  broadcasts_viewing = 'broadcasts:viewing',
  broadcasts_viewing_stop = 'broadcasts:viewing:stop',
  subscriber_update = 'subscriber:update',

  // Collection changes
  collections_created = 'collections:created',
  collections_updated = 'collections:updated',
  collections_removed = 'collections:removed',
  collections_viewers = 'collections:viewers',
  collections_viewing = 'collections:viewing',
  collections_viewing_stop = 'collections:viewing:stop',

  // Datasources changes
  datasources_created = 'datasources:created',
  datasources_updated = 'datasources:updated',
  datasources_removed = 'datasources:removed',

  // Documents changes
  documents_created = 'documents:created',
  documents_updated = 'documents:updated',
  documents_removed = 'documents:removed',

  billing_subscription_deleted = 'billing:subscription_deleted',
}

export type SystemEvent<T> = (payload: { data: T }) => void;

/**
 * Events that include the user_id and data.
 */
export type UserEvent<T> = (payload: { user_id: string; data: T }) => void;

export type AccountId = { account_id: string };
export type AgentId = { user_id: string };
export type AgentActivity = { user_id: string; timestamp: number };
export type BrainId = { account_id: string; brain_id: string };
export type BrainStatusId = BrainId & { status: Statuses };
export type DeskId = { account_id: string; desk_id: string };
export type CollectionId = { account_id: string; collection_id: string };
export type BroadcastId = { account_id: string; broadcast_id: string };
export type ConversationId = { account_id: string; session_id: string };
export type ResourceViewers = { resources: Record<string, AgentActivity[]> };
export type Viewers = { agents: AgentActivity[] };
export type Subscriber = {
  broadcast_id: string;
  external_id: string;
  status: string;
};

export type MessageId = {
  account_id: string;
  session_id: string;
  message_id: string;
};

export type NewMessage = Message & {
  account_id: string;
  assignee_agent_id: string | null;
  agent_unread_count: number;
};

// Collection resources
export type DatasourceId = { collection_id: string; datasource_id: string };
export type DocumentId = { datasource_id: string; document_id: string };

// Brain resources
export type IntentId = { brain_id: string; intent: string };
export type IntentRenameId = IntentId & { new_intent: string };
export type EntityId = { brain_id: string; entity: string };
export type EntityRenameId = EntityId & { new_entity: string };
export type WebhookId = { brain_id: string; webhook_id: string };
export type DialogId = { brain_id: string; dialog_id: string };
export type VariableId = { brain_id: string; name: string };

// Desk resources
export type RuleId = { desk_id: string; rule_id: string };
export type BundleId = { desk_id: string; bundle_id: string };
export type BusinessHoursId = { desk_id: string; business_hours_id: string };
export type IntegrationId = { desk_id: string; integration_id: string };
export type ServiceLevelAgreementId = { desk_id: string; sla_id: string };
export type MacroId = { desk_id: string; macro_id: string };
export type DeskWebhookId = { desk_id: string; webhook_id: string };

export interface UpdateCollection {
  collection: string;
  new_collection: string;
  brain_id: string;
}

/**
 * Events that the client can emit to the server.
 */
export type ServerToClientEvents = {
  [SocketEvent.agent_online]: SystemEvent<AgentId>;
  [SocketEvent.agent_offline]: SystemEvent<AgentId>;
  [SocketEvent.agent_presence]: SystemEvent<{ agents: Presence[] }>;
  [SocketEvent.agent_activity]: ClientEvent<AgentActivity & AccountId>;

  [SocketEvent.brains_created]: UserEvent<Brain>;
  [SocketEvent.brains_updated]: UserEvent<BrainId>;
  [SocketEvent.brains_removed]: UserEvent<BrainId>;
  [SocketEvent.brains_status]: UserEvent<BrainStatusId>;
  [SocketEvent.brains_viewers]: UserEvent<ResourceViewers>;
  [SocketEvent.brains_viewing]: UserEvent<BrainId & Viewers>;
  [SocketEvent.brains_viewing_stop]: UserEvent<BrainId>;

  [SocketEvent.desks_created]: UserEvent<Desk>;
  [SocketEvent.desks_updated]: UserEvent<DeskId>;
  [SocketEvent.desks_removed]: UserEvent<DeskId>;

  [SocketEvent.collections_created]: UserEvent<Collection>;
  [SocketEvent.collections_updated]: UserEvent<CollectionId>;
  [SocketEvent.collections_removed]: UserEvent<CollectionId>;
  [SocketEvent.collections_viewers]: UserEvent<ResourceViewers>;
  [SocketEvent.collections_viewing]: UserEvent<CollectionId & Viewers>;
  [SocketEvent.collections_viewing_stop]: UserEvent<CollectionId>;

  [SocketEvent.broadcasts_created]: UserEvent<Broadcast>;
  [SocketEvent.broadcasts_updated]: UserEvent<BroadcastId>;
  [SocketEvent.broadcasts_removed]: UserEvent<BroadcastId>;
  [SocketEvent.broadcasts_viewers]: UserEvent<ResourceViewers>;
  [SocketEvent.broadcasts_viewing]: UserEvent<BroadcastId & Viewers>;
  [SocketEvent.broadcasts_viewing_stop]: UserEvent<BroadcastId>;
  [SocketEvent.subscriber_update]: SystemEvent<Subscriber>;

  [SocketEvent.conversations_created]: UserEvent<Conversation>;
  [SocketEvent.conversations_expired]: SystemEvent<Conversation>;
  [SocketEvent.conversations_updated]: UserEvent<Conversation>;
  [SocketEvent.conversations_removed]: UserEvent<ConversationId>;
  [SocketEvent.conversations_reopened]: UserEvent<Conversation>;
  [SocketEvent.conversations_resolved]: UserEvent<Conversation>;
  [SocketEvent.conversations_handover]: UserEvent<Conversation>;
  [SocketEvent.conversations_viewers]: UserEvent<ResourceViewers>;
  [SocketEvent.conversations_viewing]: UserEvent<ConversationId & Viewers>;
  [SocketEvent.conversations_viewing_stop]: UserEvent<ConversationId>;
  [SocketEvent.conversations_activity]: SystemEvent<SessionActivity>;

  [SocketEvent.messages_created]: SystemEvent<NewMessage>;
  [SocketEvent.messages_compose]: SystemEvent<Typing>;
  [SocketEvent.messages_read]: SystemEvent<MarkMessages>;
  [SocketEvent.messages_delivered]: SystemEvent<MarkMessages>;

  [SocketEvent.invitations_created]: SystemEvent<Invitation>;
  [SocketEvent.invitations_updated]: SystemEvent<Invitation>;
  [SocketEvent.invitations_removed]: SystemEvent<Invitation>;
  [SocketEvent.invitations_accepted]: SystemEvent<Invitation>;
  [SocketEvent.invitations_rejected]: SystemEvent<Invitation>;

  [SocketEvent.webhooks_created]: UserEvent<Webhook>;
  [SocketEvent.webhooks_updated]: UserEvent<WebhookId>;
  [SocketEvent.webhooks_removed]: UserEvent<WebhookId>;

  [SocketEvent.intents_created]: UserEvent<Intent>;
  [SocketEvent.intents_updated]: UserEvent<IntentId>;
  [SocketEvent.intents_removed]: UserEvent<IntentId>;
  [SocketEvent.intents_renamed]: UserEvent<IntentRenameId>;
  [SocketEvent.intents_collection_updated]: UserEvent<UpdateCollection>;

  [SocketEvent.entities_created]: UserEvent<Entity>;
  [SocketEvent.entities_updated]: UserEvent<EntityId>;
  [SocketEvent.entities_removed]: UserEvent<EntityId>;
  [SocketEvent.entities_renamed]: UserEvent<EntityRenameId>;
  [SocketEvent.entities_collection_updated]: UserEvent<UpdateCollection>;

  [SocketEvent.dialogs_created]: UserEvent<Dialog>;
  [SocketEvent.dialogs_updated]: UserEvent<DialogId>;
  [SocketEvent.dialogs_removed]: UserEvent<DialogId>;
  [SocketEvent.dialogs_collection_updated]: UserEvent<UpdateCollection>;

  [SocketEvent.variables_created]: UserEvent<Variable>;
  [SocketEvent.variables_updated]: UserEvent<VariableId>;
  [SocketEvent.variables_removed]: UserEvent<VariableId>;

  [SocketEvent.versions_created]: UserEvent<BrainVersion>;
  [SocketEvent.versions_removed]: UserEvent<BrainVersion>;

  [SocketEvent.recommendations_configured]: UserEvent<Recommendation>;

  [SocketEvent.rules_created]: UserEvent<Rule>;
  [SocketEvent.rules_updated]: UserEvent<RuleId>;
  [SocketEvent.rules_removed]: UserEvent<RuleId>;
  [SocketEvent.rules_reordered]: UserEvent<RuleId>;

  [SocketEvent.bundles_created]: UserEvent<Bundle>;
  [SocketEvent.bundles_updated]: UserEvent<BundleId>;
  [SocketEvent.bundles_removed]: UserEvent<BundleId>;

  [SocketEvent.business_hours_created]: UserEvent<BusinessHours>;
  [SocketEvent.business_hours_updated]: UserEvent<BusinessHoursId>;
  [SocketEvent.business_hours_removed]: UserEvent<BusinessHoursId>;

  [SocketEvent.desk_webhooks_created]: UserEvent<DeskWebhook>;
  [SocketEvent.desk_webhooks_updated]: UserEvent<DeskWebhookId>;
  [SocketEvent.desk_webhooks_removed]: UserEvent<DeskWebhookId>;

  [SocketEvent.integrations_created]: UserEvent<Integration>;
  [SocketEvent.integrations_updated]: UserEvent<IntegrationId>;
  [SocketEvent.integrations_removed]: UserEvent<IntegrationId>;

  [SocketEvent.slas_created]: UserEvent<ServiceLevelAgreement>;
  [SocketEvent.slas_updated]: UserEvent<ServiceLevelAgreementId>;
  [SocketEvent.slas_removed]: UserEvent<ServiceLevelAgreementId>;

  [SocketEvent.macros_created]: UserEvent<Macro>;
  [SocketEvent.macros_updated]: UserEvent<MacroId>;
  [SocketEvent.macros_removed]: UserEvent<MacroId>;

  [SocketEvent.datasources_created]: UserEvent<Datasource>;
  [SocketEvent.datasources_updated]: UserEvent<DatasourceId>;
  [SocketEvent.datasources_removed]: UserEvent<DatasourceId>;

  [SocketEvent.documents_created]: UserEvent<Document>;
  [SocketEvent.documents_updated]: UserEvent<DocumentId>;
  [SocketEvent.documents_removed]: UserEvent<DocumentId>;

  [SocketEvent.billing_subscription_deleted]: UserEvent<AccountId>;
};

export type SocketCallback = (err?: string) => void;
type ClientEvent<T> = (payload: T, cb?: SocketCallback) => void;

/**
 * Events that the client can emit to the server.
 */
export type ClientToServerEvents = {
  [SocketEvent.subscribe_account]: ClientEvent<AccountId>;
  [SocketEvent.subscribe_brain]: ClientEvent<{ brain_id: string }>;
  [SocketEvent.subscribe_broadcast]: ClientEvent<{ broadcast_id: string }>;
  [SocketEvent.subscribe_desk]: ClientEvent<{ desk_id: string }>;
  [SocketEvent.subscribe_collection]: ClientEvent<{ collection_id: string }>;
  [SocketEvent.subscribe_datasource]: ClientEvent<DatasourceId>;
  [SocketEvent.subscribe_conversation]: ClientEvent<ConversationId>;

  [SocketEvent.unsubscribe_account]: ClientEvent<AccountId>;
  [SocketEvent.unsubscribe_brain]: ClientEvent<{ brain_id: string }>;
  [SocketEvent.unsubscribe_broadcast]: ClientEvent<{ broadcast_id: string }>;
  [SocketEvent.unsubscribe_desk]: ClientEvent<{ desk_id: string }>;
  [SocketEvent.unsubscribe_collection]: ClientEvent<{ collection_id: string }>;
  [SocketEvent.unsubscribe_datasource]: ClientEvent<DatasourceId>;
  [SocketEvent.unsubscribe_conversation]: ClientEvent<ConversationId>;

  [SocketEvent.agent_activity]: ClientEvent<AgentActivity & AccountId>;
};

export type Socket = SocketClient<ServerToClientEvents, ClientToServerEvents>;
