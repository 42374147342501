import { useMemo } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { viewsEndpoints as endpoints } from '@/api/endpoints';
import { callDelete, callGet, callPost, callPut } from '@/api/fetcher';
import { MODAL_DELETE } from '@/components/organisms/Modals/ModalConductor';
import { View } from '@/models/views';
import { MenuItem } from '@/modules/humanChat/models';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import { pushModal } from '@/redux/modals/actions';

import { useAccount } from './useAccount';

type UpdateProps = {
  view_id: string;
  view: Partial<View>;
};

type Views = {
  views: View[];
};

/**
 * TODO:
 * - add tests
 * - add error handling
 * - add loading state
 * - add pagination
 * - add translations
 */

const API = Object.freeze({
  listViews: async (deskId: string): Promise<Views> =>
    callGet(endpoints.views(deskId)),
  updateView: async (
    desk_id: string,
    view_id: string,
    view: Partial<View>
  ): Promise<View> => callPut(endpoints.view(desk_id, view_id), view),
  createView: async (desk_id: string, view: Partial<View>): Promise<View> =>
    callPost(endpoints.views(desk_id), view),
  deleteView: async (desk_id: string, view_id: string): Promise<View> =>
    callDelete(endpoints.view(desk_id, view_id)),
});
export const useViews = (deskId?: string) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { slug } = useAccount();

  const {
    data: views,
    isLoading: _isViewsLoading,
    error: _viewsError,
  } = useQuery({
    queryKey: [endpoints.views(deskId)],
    queryFn: () => API.listViews(deskId),
    enabled: Boolean(deskId),
  });

  const { mutate: createView, status: _createStatus } = useMutation<
    View,
    Error,
    Partial<View>
  >({
    mutationFn: (view: View) => API.createView(deskId, view),
    onSuccess: (resp) => {
      queryClient.setQueryData([endpoints.views(deskId)], (prev: View[]) => {
        return prev.push(resp);
      });
      dispatch(
        addTemporalToast('success', t('views.view_created', { 0: resp.name }))
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: updateView, status: _updateStatus } = useMutation<
    View,
    Error,
    UpdateProps
  >({
    mutationFn: ({ view_id, view }) => API.updateView(deskId, view_id, view),
    onSuccess: (resp) => {
      queryClient.setQueryData([endpoints.views(deskId)], (prev: View[]) => {
        return prev.map((item) =>
          item.view_id === resp.view_id ? resp : item
        );
      });
      dispatch(
        addTemporalToast(
          'success',
          t('conversation.view_updated', { 0: resp.name })
        )
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: deleteView } = useMutation<View, Error, string>({
    mutationFn: (view_id) => API.deleteView(deskId, view_id),
    onSuccess: (resp) => {
      queryClient.setQueryData<Views>([endpoints.views(deskId)], (prev) => {
        return {
          ...prev,
          views: prev?.views?.filter((item) => item.view_id !== resp.view_id),
        };
      });
      dispatch(
        addTemporalToast(
          'success',
          t('conversation.view_deleted', { 0: resp.name })
        )
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const menuItems = useMemo<MenuItem[]>(
    () =>
      views?.views?.map((view) => {
        return {
          text: view.name,
          onClick: () =>
            navigate(
              `/${slug}/chats/${deskId}/conversations?view_id=${view.view_id}`
            ),
          id: view.view_id,
          options: [
            { label: t('common.edit'), fn: () => {} },
            {
              label: t('common.delete'),
              fn: () => {
                dispatch(
                  pushModal(MODAL_DELETE, {
                    onConfirm: () => deleteView(view.view_id),
                  })
                );
              },
            },
          ],
        };
      }) ?? [],
    [views?.views, navigate, slug, deskId, dispatch, t, deleteView]
  );

  return { views: menuItems, updateView, deleteView, createView };
};
