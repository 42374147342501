import { useEffect } from 'react';

import Box from '@mui/material/Box';
import uniqBy from 'lodash/uniqBy';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Input from '@/components/atoms/Input/Input';
import Select from '@/components/atoms/Select/Select';
import { BrainLanguage } from '@/models/brain';
import { QuickLanguageOption } from '@/modules/aiAgents/components/QuickLanguageOption/QuickLanguageOption';
import useLanguageModels from '@/modules/developerTools/hooks/useLanguageModels';
import { providerNameMapper } from '@/modules/developerTools/pages/languageModels/helper';
import { ProviderName } from '@/modules/developerTools/types';
import { languageByCountryCode } from '@/util/constants';
import { capitalizeFirstLetter } from '@/util/util';

import { Form } from './ModalAIAgentCreate';

export const Step2 = () => {
  // Custom hooks
  const { t } = useTranslation();
  const { enabledModels, enabledModelsWithMoveo } = useLanguageModels();

  // RHF
  const {
    watch,
    setValue,
    register,
    getValues,
    formState: { errors },
  } = useFormContext<Form>();

  useEffect(() => {
    setValue('provider', 'moveo');
    setValue('language_model_id', 'Default');
  }, [setValue]);

  // Local variables
  const languageOptions = Object.keys(languageByCountryCode).map((i) => ({
    value: i.toLowerCase(),
    label: t(`languages.${i.toLowerCase()}`, {
      defaultValue: languageByCountryCode[i],
    }),
  }));
  const defaultName =
    t(`ai_agents.types.${getValues('brain_type')}.title`) +
    ' ' +
    t('analytics.fields.agent');
  const watchLanguage = watch('language');
  const hasSelectedProvider = watch('provider') !== '';
  const llms = enabledModels.length > 0 ? enabledModelsWithMoveo : [];
  const uniqueProviders = uniqBy(llms, 'provider');

  // Handlers
  const handleChangeLanguage = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const language = (e.currentTarget as HTMLButtonElement).dataset
      .language as BrainLanguage;
    setValue('language', language, {
      shouldValidate: true,
    });
  };

  return (
    <>
      <Input
        {...register('name')}
        onChange={async (e) => {
          setValue('name', e.target.value, {
            shouldValidate: true,
          });
        }}
        defaultValue={defaultName}
        size="medium"
        label={t('common.name')}
        placeholder={t('signup.name_placeholder')}
        error={!!errors.name?.message}
        errorMessage={capitalizeFirstLetter(errors.name?.message)}
      />

      <Select
        {...register('language')}
        onChange={async (e) => {
          setValue('language', e.target.value as BrainLanguage, {
            shouldValidate: true,
          });
        }}
        defaultValue={getValues('language') ?? ''}
        label={t('field.language')}
        placeholder={t('common.select_an_option')}
        options={languageOptions}
        size="full"
        error={!!errors.language?.message}
        errorMessage={capitalizeFirstLetter(errors.language?.message)}
      />

      <Box display="flex" gap="var(--space-8)" alignItems="center">
        <QuickLanguageOption
          data-language={BrainLanguage.ENGLISH}
          handleClick={handleChangeLanguage}
          language="en"
          isActive={watchLanguage === 'en'}
        />
        <QuickLanguageOption
          data-language={BrainLanguage.PORTUGUESE_BRAZILIAN}
          handleClick={handleChangeLanguage}
          language="pt-br"
          isActive={watchLanguage === 'pt-br'}
        />
        <QuickLanguageOption
          data-language={BrainLanguage.GREEK}
          handleClick={handleChangeLanguage}
          language="el"
          isActive={watchLanguage === 'el'}
        />
      </Box>

      {enabledModels?.length > 0 && (
        <Box mt="var(--space-24)" display="flex" gap="var(--space-16)">
          <Select
            size="full"
            name="provider"
            label={t('field.provider')}
            placeholder={t('common.select_an_option')}
            register={register('provider')}
            error={!!errors.provider}
            errorMessage={errors.provider?.message}
            options={uniqueProviders?.map((model) => ({
              label: providerNameMapper(model.provider as ProviderName),
              value: model.provider,
            }))}
            onChange={(e) => {
              const selected = e.target.value;
              const firstModelOption = uniqueProviders.find(
                (m) => m.provider === selected
              );
              setValue(
                'language_model_id',
                firstModelOption?.language_model_id
              );
            }}
          />

          <Select
            size="full"
            name="language_model_id"
            disabled={!hasSelectedProvider}
            label={t('field.model')}
            placeholder={t('common.select_an_option')}
            register={register('language_model_id')}
            error={!!errors.language_model_id}
            errorMessage={errors.language_model_id?.message}
            options={llms
              ?.filter((model) => model.provider === watch('provider'))
              ?.map((model) => ({
                label: model.model,
                value: model.language_model_id as string,
              }))}
          />
        </Box>
      )}
    </>
  );
};
