import { Icon } from '@/models/icon';

export const Microphone = ({
  color = 'var(--icon-default-gray)',
  size = 26,
}: Icon) => {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
    >
      <rect x="6.5" y="2" width="5" height="8.75" rx="2.5" stroke={color} />
      <path
        d="M13.75 8.625c0 .678-.125 1.348-.366 1.972a5.167 5.167 0 0 1-1.037 1.664 4.74 4.74 0 0 1-1.54 1.104A4.447 4.447 0 0 1 9 13.75c-.619 0-1.232-.13-1.806-.385a4.74 4.74 0 0 1-1.54-1.104 5.167 5.167 0 0 1-1.038-1.664 5.476 5.476 0 0 1-.366-1.972"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 16.5v-2.25m0 2.25h1.5m-1.5 0H7.5"
        stroke={color}
        strokeLinecap="round"
      />
    </svg>
  );
};
