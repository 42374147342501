import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import { MODAL_CUSTOM_GUIDELINE_CREATE } from '@/components/organisms/Modals/ModalConductor';
import { pushModal } from '@/redux/modals/actions';

import { useKnowledge } from '../../hooks/useKnowledge';
import { PlusIcon } from '../../icons/PlusIcon';

export const AddGuidelineButton = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { hasNoCustomGuidelines } = useKnowledge();
  const buttonText = hasNoCustomGuidelines
    ? t('ai_agents.knowledge.add_guideline')
    : t('ai_agents.knowledge.add_new_guideline');

  return (
    <Button
      noGutters
      size="small"
      variant="tertiary"
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        dispatch(pushModal(MODAL_CUSTOM_GUIDELINE_CREATE));
      }}
    >
      <PlusIcon color="currentColor" />
      {buttonText}
    </Button>
  );
};
