import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {
  ThumbsDownIcon,
  ThumbsUpIcon,
} from '@/modules/TryIt/Icons/ThumbsIcons';

export const ThumbsCounter = ({ thumbsUp, thumbsDown }) => {
  return (
    <Box display="flex" gap="var(--space-8)" alignItems="center">
      <Typography
        color="var(--text-default-black)"
        display="flex"
        gap="var(--space-4)"
        alignItems="center"
      >
        {thumbsUp}
        <ThumbsUpIcon color="var(--icon-default-gray)" />
      </Typography>
      <Typography
        variant="label-regular"
        color="var(--text-placeholder-gray)"
        sx={{
          transform: 'translateY(-2px)',
        }}
      >
        .
      </Typography>
      <Typography display="flex" gap="var(--space-4)" alignItems="center">
        {thumbsDown}
        <ThumbsDownIcon color="var(--icon-default-gray)" />
      </Typography>
    </Box>
  );
};
