import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import useBrains from '@/hooks/useBrains';
import { selectBrainId } from '@/redux/session/selectors';

import {
  getMaximumCharactersByLanguage,
  sumCurrentCharacters,
} from '../../helper';
import { InfoIcon } from '../../icons/InfoIcon';

import styles from './Knowledge.module.scss';

export const CharactersLinearProgress = () => {
  const { t } = useTranslation();
  const brainId = useSelector(selectBrainId);
  const { brain } = useBrains(brainId);
  const { language, guidelines } = brain;
  const currentCharacters = sumCurrentCharacters(guidelines);
  const maxCharacters = getMaximumCharactersByLanguage(language);
  const progressValue =
    (Math.min(currentCharacters, maxCharacters) / maxCharacters) * 100;

  return (
    <Box display={'flex'} alignItems={'center'} gap="var(--space-8)">
      <LinearProgress
        sx={{
          '.MuiLinearProgress-bar1': {
            backgroundColor: 'var(--icon-default-blue)',
          },
        }}
        className={styles.characterLimitBar}
        variant="determinate"
        value={progressValue}
      />

      <Tooltip
        arrow
        title={
          <Box padding="var(--space-8) var(--space-4)">
            <Typography
              component="h6"
              variant="label-semi-bold"
              mb="var(--space-4)"
            >
              {t('ai_agents.character_limit.tooltip.title')}
            </Typography>
            <Typography component="p" variant="label-regular">
              <Trans
                i18nKey="ai_agents.character_limit.tooltip.body"
                values={{
                  current_characters: currentCharacters,
                  max_characters: maxCharacters,
                }}
              />
            </Typography>
          </Box>
        }
      >
        <InfoIcon color="var(--icon-default-gray)" />
      </Tooltip>
    </Box>
  );
};
