import { useCallback } from 'react';

import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import Input from '@/components/atoms/Input/Input';
import useBrains from '@/hooks/useBrains';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectBrainId } from '@/redux/session/selectors';
import { delay, scrollToElementById } from '@/util/util';
import { brainRules, errorMessage } from '@/util/validator';

import { useTrackFormState } from '../../hooks/useTrackFormState';
import { FormCard } from '../FormCard/FormCard';
import { NumberIcon } from '../NumberIcon/NumberIcon';

type Form = {
  confidence_threshold: string;
};

const FORM_ID = 'ai-agent-confidence-threshold';

interface Props {
  number: number;
  show: boolean;
  onToggle: () => void;
}

export const AdvancedSettings = ({ number, show, onToggle }: Props) => {
  const brainId = useSelector(selectBrainId);
  const { t } = useTranslation();
  const { brain, updateBrain } = useBrains(brainId);

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );

  const {
    formState: { isSubmitting, isDirty, errors },
    handleSubmit,
    register,
  } = useForm<Form>({
    mode: 'onSubmit',
    values: {
      confidence_threshold: brain?.confidence_threshold?.toString(),
    },
  });

  const onSubmit = useCallback<(form: Form) => void>(
    (form) => {
      const parsedThreshold = parseFloat(form?.confidence_threshold);
      updateBrain({
        confidence_threshold: parsedThreshold,
        brain_id: brainId,
      });
    },
    [brainId, updateBrain]
  );
  useTrackFormState({ isDirty, formId: FORM_ID });

  const handleToggle = useCallback(async () => {
    onToggle();
    await delay(200);
    scrollToElementById('ai-agent-confidence-threshold');
  }, [onToggle]);

  return (
    <>
      <Box justifyContent="center" display="flex" gap="var(--space-8)">
        <Button onClick={handleToggle} variant="tertiary">
          {show ? t('common.hide') : t('common.show')}{' '}
          {t('ai_agents.advanced_settings')}
        </Button>
      </Box>
      {show && (
        <FormCard onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
          <FormCard.Header
            title={t('brains.confidence_title')}
            subtitle={t('brains.confidence_subtitle')}
            icon={
              <NumberIcon
                color="var(--icon-default-blue)"
                size="large"
                number={number}
              />
            }
          />
          <FormCard.Content>
            <Input
              name="confidence_threshold"
              label={t('brains.threshold')}
              register={register(
                'confidence_threshold',
                brainRules.confidenceThreshold
              )}
              errorMessage={errorMessage({
                field: errors.confidence_threshold,
                maxValue: 1,
                minValue: 0,
              })}
              placeholder=""
              size="small"
              defaultValue={brain?.confidence_threshold}
              type="number"
              step="0.01"
              readOnly={!canWrite}
            />
          </FormCard.Content>

          <FormCard.Footer>
            <Button
              type="submit"
              variant="secondary"
              isLoading={isSubmitting}
              disabled={!isDirty}
            >
              {t('common.save')}
            </Button>
          </FormCard.Footer>
        </FormCard>
      )}
    </>
  );
};
