import { Icon } from '@/models/icon';

export const ThumbsUpIcon = ({
  size = 16,
  color = 'currentColor',
  ...rest
}: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 17 17"
      {...rest}
    >
      <g clipPath="url(#a-thumbsUp)">
        <path
          fill={color}
          d="M1.164 10.955c-.33-.417-.49-.864-.472-1.326a2.04 2.04 0 0 1 .43-1.153c-.22-.541-.309-1.394.414-2.06.53-.488 1.432-.71 2.684-.656.88.036 1.617.196 1.647.202h.003c.168.03.345.066.526.105.012-.214-.027-.746-.428-1.942-.476-1.423-.455-2.514.07-3.246C6.59.11 7.476.045 7.738.044a.84.84 0 0 1 .646.291c.374.43.335 1.226.29 1.595.447 1.182 1.7 4.08 2.751 4.88a.36.36 0 0 1 .054.047c.31.323.519.674.66.98.198-.107.422-.169.663-.17l2.04-.01a1.393 1.393 0 0 1 1.4 1.385l.027 5.437a1.393 1.393 0 0 1-1.385 1.399l-2.04.01a1.39 1.39 0 0 1-.805-.25l-.786.097c-.12.018-2.26.3-4.459.267-.398.032-.772.05-1.12.053a7.515 7.515 0 0 1-1.557-.133c-1.025-.212-1.726-.644-2.084-1.28a2.048 2.048 0 0 1-.234-1.281c-.669-.6-.79-1.264-.768-1.733a2.26 2.26 0 0 1 .133-.673Zm13.716 4.02a.489.489 0 0 0 .486-.492l-.028-5.44a.49.49 0 0 0-.491-.486l-2.041.01a.49.49 0 0 0-.486.491l.028 5.437a.49.49 0 0 0 .49.486l2.042-.01v.003ZM2.018 10.558a.45.45 0 0 1 .063.545c0 .004-.136.239-.151.56-.021.438.191.825.635 1.154.158.12.222.327.156.515 0 .003-.141.445.095.862.226.4.726.686 1.483.843.606.127 1.43.146 2.44.061h.046c2.152.036 4.325-.256 4.349-.26h.003l.338-.042a1.42 1.42 0 0 1-.032-.294l-.027-5.44c-.001-.144.022-.285.061-.415a2.796 2.796 0 0 0-.628-1.145C9.342 6.32 7.852 2.309 7.788 2.135a.424.424 0 0 1-.021-.224c.055-.375.032-.833-.049-.97-.177.004-.655.057-.94.456-.34.474-.322 1.317.052 2.433.57 1.7.622 2.596.179 2.993a.73.73 0 0 1-.729.134 9.45 9.45 0 0 0-.583-.114l-.043-.01c-1.029-.219-2.87-.346-3.506.239-.54.498-.151 1.152-.107 1.222a.447.447 0 0 1-.078.582c-.003.004-.353.337-.367.782-.012.298.13.601.423.901Z"
        />
      </g>
      <defs>
        <clipPath id="a-thumbsUp">
          <path fill="#fff" d="m16.419 0-16 .081.081 16 16-.081z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ThumbsDownIcon = ({
  size = 16,
  color = 'currentColor',
  ...rest
}: Icon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 17 16"
      {...rest}
    >
      <g clipPath="url(#a-thumbsDown)">
        <path
          fill={color}
          d="M15.729 5.123c.328.418.485.866.465 1.328a2.04 2.04 0 0 1-.435 1.15c.218.543.301 1.396-.425 2.059-.532.485-1.435.702-2.687.642-.88-.04-1.616-.204-1.646-.21h-.003c-.167-.03-.345-.068-.525-.108-.014.214.023.746.418 1.944.468 1.425.441 2.516-.087 3.246-.556.766-1.442.826-1.703.826a.84.84 0 0 1-.646-.294c-.371-.432-.328-1.228-.281-1.596-.442-1.185-1.68-4.09-2.727-4.895a.359.359 0 0 1-.053-.047 3.594 3.594 0 0 1-.656-.984 1.38 1.38 0 0 1-.663.167h-2.04c-.77 0-1.393-.625-1.393-1.392V1.522C.642.752 1.268.13 2.034.13h2.041c.298 0 .576.094.803.255L5.665.29c.12-.017 2.261-.288 4.46-.244.398-.03.773-.047 1.12-.047.6 0 1.121.047 1.556.14 1.024.218 1.723.653 2.078 1.292.271.489.271.974.228 1.282.666.602.783 1.268.76 1.736a2.26 2.26 0 0 1-.138.673ZM2.034 1.033a.49.49 0 0 0-.488.49v5.44c0 .27.22.488.488.488h2.041a.49.49 0 0 0 .489-.488V1.526a.49.49 0 0 0-.489-.489h-2.04v-.003Zm12.838 4.481a.45.45 0 0 1-.06-.545c0-.004.137-.238.154-.56.024-.437-.187-.826-.629-1.157a.456.456 0 0 1-.154-.515c0-.003.144-.445-.09-.863-.224-.402-.723-.69-1.479-.85-.606-.13-1.429-.154-2.44-.074h-.046c-2.151-.047-4.326.234-4.35.238h-.003l-.338.04c.02.094.03.194.03.294v5.44c0 .145-.023.285-.063.416.06.224.227.722.622 1.147 1.502 1.191 2.971 5.21 3.035 5.384.026.07.033.147.02.224-.057.375-.037.833.043.97.178-.003.656-.053.944-.451.341-.472.328-1.315-.04-2.433-.562-1.703-.61-2.6-.164-2.994a.73.73 0 0 1 .73-.13c.203.046.397.086.581.116l.044.01c1.027.224 2.867.362 3.506-.22.542-.496.158-1.152.114-1.222a.447.447 0 0 1 .08-.582c.004-.003.355-.335.372-.78.013-.297-.127-.602-.418-.903Z"
        />
      </g>
      <defs>
        <clipPath id="a-thumbsDown">
          <path fill="#fff" d="M.42 16h16V0h-16z" />
        </clipPath>
      </defs>
    </svg>
  );
};
