import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * Custom hook for audio controls
 */
export const useAudioControls = () => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [pausedTime, setPausedTime] = useState<number | null>(null);

  const handlePlayClick = useCallback(() => {
    if (!audioRef.current) return;

    if (isPlaying) {
      audioRef.current.pause();
      setPausedTime(audioRef.current.currentTime);
      setIsPaused(true);
    } else {
      if (isPaused && pausedTime !== null) {
        audioRef.current.currentTime = pausedTime;
      } else {
        audioRef.current.currentTime = 0;
      }
      audioRef.current.play();
      setIsPaused(false);
      setPausedTime(null);
    }
    setIsPlaying(!isPlaying);
  }, [isPlaying, isPaused, pausedTime]);

  /**
   * Resets state when audio ends
   */
  const handleAudioEnded = useCallback(() => {
    setIsPlaying(false);
    setIsPaused(false);
    setPausedTime(null);
    setCurrentTime(0);
  }, []);

  /**
   * Updates currentTime on each timeupdate event
   */
  const handleTimeUpdate = useCallback(() => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  }, []);

  /**
   * Set the audio ref and attach event listeners
   * Sometimes the audioRef.current is null, so we need to check if it exists before adding the event listener
   * */
  const setAudioRef = useCallback(
    (node: HTMLAudioElement | null) => {
      if (audioRef.current) {
        // Clean up previous listeners
        audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
        audioRef.current.removeEventListener('ended', handleAudioEnded);
      }

      if (node) {
        // Assign the new audio element to the ref
        audioRef.current = node;

        // Attach listeners
        node.addEventListener('timeupdate', handleTimeUpdate);
        node.addEventListener('ended', handleAudioEnded);
      } else {
        audioRef.current = null;
      }
    },
    [handleTimeUpdate, handleAudioEnded]
  );

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
        audioRef.current.removeEventListener('ended', handleAudioEnded);
      }
    };
  }, [handleTimeUpdate, handleAudioEnded]);

  return {
    setAudioRef,
    isPlaying,
    currentTime,
    handlePlayClick,
    handleAudioEnded,
    pausedTime,
    isPaused,
    setPausedTime,
    setIsPaused,
  };
};
