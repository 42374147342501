import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const slice = createSlice({
  name: 'aiAgents',
  initialState,
  reducers: {
    // Update or add a form's dirty state
    updateFormDirtyState: (state, action) => {
      const { formId, isDirty } = action.payload;
      state[formId] = isDirty;
    },
    // Remove a specific form from the state
    removeFormState: (state, action) => {
      const { formId } = action.payload;
      delete state[formId];
    },
    // Reset all forms in the state
    resetAllFormStates: () => {
      return {};
    },
  },
});

export const { updateFormDirtyState, removeFormState, resetAllFormStates } =
  slice.actions;

export default slice.reducer;
