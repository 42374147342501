import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Checkmark from '@/components/atoms/Icons/Checkmark';
import useBrains from '@/hooks/useBrains';
import { selectBrainId } from '@/redux/session/selectors';
import { scrollToElementById } from '@/util/util';

import { AddGuidelineButton } from './AddGuidelineButton';
import { CharactersLinearProgress } from './CharactersLinearProgress';
import { CustomGuidelineForm } from './CustomGuidelineForm';
import { Objections } from './Objections';
import { StaticKnowledge } from './StaticKnowledge';
import { useKnowledge } from '../../hooks/useKnowledge';
import { NumberIcon } from '../NumberIcon/NumberIcon';
import { Summary } from '../Summary/Summary';

export const DebtCollectionKnowledge = () => {
  const { t } = useTranslation();
  const { sortedCustomGuidelines } = useKnowledge();

  return (
    <>
      <Box pl="var(--space-16)">
        <Typography
          component="h3"
          variant="h3-semi-bold"
          color="var(--text-default-black)"
        >
          {t('ai_agents.knowledge.title_debt_collection')}
        </Typography>
        <Typography component="p" color="var(--text-default-gray)">
          {t('ai_agents.knowledge.subtitle_customer_support')}
        </Typography>
      </Box>

      <Objections order={1} />

      {sortedCustomGuidelines.map((customGuideline, index) => {
        return (
          <CustomGuidelineForm
            key={customGuideline.created_at}
            {...customGuideline}
            // Order starts from 2, because the first one is the Objections
            order={index + 2}
          />
        );
      })}
    </>
  );
};

export const DebtCollectionSummary = () => {
  const brainId = useSelector(selectBrainId);
  const { brain } = useBrains(brainId);
  const { t } = useTranslation();
  const { sortedCustomGuidelines } = useKnowledge();
  const objections = brain?.guidelines?.objections || [];
  const hasObjections = objections.length > 0;

  return (
    <>
      <Summary
        icon={<CharactersLinearProgress />}
        button={
          <Box mt="var(--space-16)">
            <AddGuidelineButton />
          </Box>
        }
      >
        <Summary.Step
          onClick={() => scrollToElementById('ai-agent-objections')}
          text={t('ai_agents.knowledge.objections.title')}
          icon={hasObjections ? <Checkmark /> : <NumberIcon number={1} />}
        />
        {sortedCustomGuidelines.map((customGuideline, index) => {
          const order = index + 2;
          const icon = customGuideline.body ? (
            <Checkmark />
          ) : (
            <NumberIcon number={order} />
          );
          return (
            <Summary.Step
              key={customGuideline.created_at}
              onClick={() => scrollToElementById(`custom-guideline-${order}`)}
              text={customGuideline.title}
              icon={icon}
            />
          );
        })}
      </Summary>

      <StaticKnowledge />

      {/* Hide live instructions until NLP team implments it */}
      {/* <LiveInstructions /> */}
    </>
  );
};
