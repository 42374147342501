import { LinkPlugin } from '@udecode/plate-link/react';

import { urlPattern } from '@/util/validator';

import { LinkFloatingToolbar } from '../../plate-ui/link-floating-toolbar';

export const linkPlugin = LinkPlugin.extend({
  render: { afterEditable: () => <LinkFloatingToolbar /> },
  options: {
    isUrl: (text: string) => {
      const isValidUrl = urlPattern.test(text);
      return isValidUrl;
    },
  },
});
